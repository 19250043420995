import React from 'react'

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Checkbox, IconButton, TablePagination, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './index.css'
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#EFF9F9",
        color: "#141414",
        fontSize: 17
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        color: "#141414",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        // cursor: 'pointer'

    },

    '&:nth-of-type(even)': {
        // cursor: 'pointer'
    },

    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        // cursor: 'pointer'
    },
}));


const FundTable = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = props.rows;

    return (
        <Paper sx={{ width: '100%', marginTop: "10px" }}>
            <TableContainer>
                <Table stickyHeader size='small' aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            {props.title === 'SELECTED FUNDS' && <StyledTableCell></StyledTableCell>}
                            <StyledTableCell>ISINCode</StyledTableCell>
                            <StyledTableCell align="left">Fund Name</StyledTableCell>
                            <StyledTableCell align="left">AMC</StyledTableCell>
                            <StyledTableCell align="left">Asset Class</StyledTableCell>
                            <StyledTableCell align="left">Segment</StyledTableCell>
                            <StyledTableCell align="left">Started On</StyledTableCell>
                            {props.title === 'SELECTED FUNDS' && props.filters.portfolio && <StyledTableCell align="left">Weight</StyledTableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <StyledTableRow key={row.fund_id}>
                                        <StyledTableCell sx={{ padding: '6px 0px 6px 10px' }}>
                                            <Checkbox checked={props.isSelected(row.fund_id)} onClick={(e) => props.handleFundsSelected(e, row)} />
                                        </StyledTableCell>
                                        {props.title === 'SELECTED FUNDS' &&
                                            <StyledTableCell sx={{ padding: '6px 0px 6px 0px' }}>
                                                <IconButton aria-label="delete" size="large" sx={{ padding: '12px 0px' }} onClick={(e) => props.handleFundsSelected(e, row, 'delete')}>
                                                    <DeleteIcon fontSize='' />
                                                </IconButton>
                                            </StyledTableCell>}
                                        <StyledTableCell component="th" scope="row">
                                            {row.isin_code}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.fund_name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.asset_management_company_name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.asset_class_name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.segment_name}</StyledTableCell>
                                        <StyledTableCell align="left">{moment(row.start_date).format('MMM-YYYY')}</StyledTableCell>
                                        {props.title === 'SELECTED FUNDS' && props.filters.portfolio && <StyledTableCell align="left">
                                            <TextField
                                                defaultValue='hello world'
                                                autoComplete='off'
                                                type='number'
                                                id="standard-basic"
                                                label="Weight"
                                                variant="outlined"
                                                size="small"
                                                value={parseInt(props.filters.weights[row.fund_id] * 100) || ''}
                                                onChange={(e) => props.onChangePortfolio({ [row.fund_id]: parseFloat(e.target.value) / 100 }, 'weights')}
                                                onFocus={event => {
                                                    event.target.select();
                                                }} />

                                        </StyledTableCell>}
                                    </StyledTableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper >
    );
}


export default FundTable
