
import authenticateUser from "../utils/userAuthenticate"

const setUserDataService = async () => {
    

    const userID = await authenticateUser();

    return {
        
        userId: userID
    }
    
}
export default setUserDataService;