import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import api from '../../config/api';

class PeriodReturnChart extends Component {
    state = {
        data: [],
        chartData: {
            labels: [],
            datasets: []
        },
        loading: true,
        isError: false
    }


    componentDidMount = async () => {
        console.log("Period Return Chart: componentDidMount");
        try {
            let data
            if (this.props.inputData.portfolio) {
                const { startDate, endDate, portfolioAmt, rebalance, weights, benchmark } = this.props.inputData
                data = await api.get(`/portfolio/charts/period-return-chart`, {
                    params: {
                        start: startDate,
                        end: endDate,
                        amount: portfolioAmt,
                        rebalance: rebalance,
                        // assetSegmentId: assetClassId + segmentId,
                        weights: weights,
                        benchmarkId: benchmark
                    }
                })

            }
            else {
                data = await api.get(`/charts/period-return-chart/${this.props.inputData.fundID}?benchmarkId=${this.props.inputData.benchmark}&end=${this.props.inputData.endDate}&funds=${JSON.stringify(this.props.inputData.funds)}`)
            }
            this.setState({ data: data.data, isError: false })
            this.createChartData()
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }
    }

    componentDidUpdate = async (prevProp) => {
        console.log("Period Return Chart: componentDidUpdate");
        try {
            if (JSON.stringify(prevProp.inputData) !== JSON.stringify(this.props.inputData)) {
                let data
                console.log(prevProp.inputData);
                console.log(this.props.inputData);
                this.setState({ loading: true })
                if (this.props.inputData.portfolio) {
                    const { startDate, endDate, portfolioAmt, rebalance, weights, benchmark } = this.props.inputData
                    data = await api.get(`/portfolio/charts/period-return-chart`, {
                        params: {
                            start: startDate,
                            end: endDate,
                            amount: portfolioAmt,
                            rebalance: rebalance,
                            // assetSegmentId: assetClassId + segmentId,
                            weights: weights,
                            benchmarkId: benchmark
                        }
                    })

                }
                else {
                    data = await api.get(`/charts/period-return-chart/${this.props.inputData.fundID}?benchmarkId=${this.props.inputData.benchmark}&end=${this.props.inputData.endDate}&funds=${JSON.stringify(this.props.inputData.funds)}`)
                }

                this.setState({ data: data.data, isError: false })
                this.createChartData()
            }
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }
    }

    fund_name = ""
    benchmark_name = ""

    createChartData = () => {
        const serverData = this.state.data
        const isExcess = this.props.inputData.excessReturn
        this.fund_name = this.props.allFunds[this.state.data.fund_id] ? this.props.allFunds[this.state.data.fund_id].fund_name : 'Portfolio'
        this.benchmark_name = this.props.allBenchmarks[this.props.inputData.benchmark].fund_name

        const getRandomColor = () => {
            const red = Math.floor(Math.random() * 255)
            const green = Math.floor(Math.random() * 255)
            const blue = Math.floor(Math.random() * 255)

            return [`rgb(${red}, ${green}, ${blue})`, `rgba(${red}, ${green}, ${blue}, ${80})`]
        }

        let chartData = {
            labels: [],
            datasets: []
        }
        const backgroundColor = [
            '#269f64',
            '#1fb4d5',
            '#002850',
            '#3a6bfa',
            '#833e80',
        ]
        const borderColor = [
            '#269f6480',
            '#1fb4d580',
            '#00285080',
            '#3a6bfa80',
            '#833e8080',
        ]
        let counter = 0

        // if (!isExcess) {
        for (const fund_id in serverData['fund_return']) {
            if (Object.hasOwnProperty.call(serverData['fund_return'], fund_id)) {
                let val = serverData['fund_return'][fund_id];
                let x = {
                    label: '',
                    fill: false,
                    backgroundColor: 'rgb(54, 162, 235)',
                    borderColor: 'rgb(54, 162, 235)',
                    data: [],
                    pointRadius: 3,
                    pointHoverRadius: 5,
                }
                const [a, b] = getRandomColor()
                for (const key in val) {
                    if (Object.hasOwnProperty.call(val, key)) {
                        let ele = val[key];
                        if (isExcess) {
                            ele = ele - serverData['index_return'][key]
                        }
                        x.data.push(ele * 100)
                        x.label = this.props.allFunds[fund_id] ? this.props.allFunds[fund_id].fund_name : 'Portfolio'
                        x.backgroundColor = backgroundColor[counter] || a
                        x.borderColor = borderColor[counter] || b
                    }
                }
                chartData.datasets.push(x)
                counter++

                // chartData.datasets[0].data.push(val * 100)
                // chartData.labels.push(key)

            }
        }
        let x = {
            label: '',
            fill: false,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            data: [],
            pointRadius: 3,
            pointHoverRadius: 5,
        }

        for (const key in serverData['index_return']) {
            if (Object.hasOwnProperty.call(serverData['index_return'], key)) {
                const val = serverData['index_return'][key];
                [x.backgroundColor, x.borderColor] = getRandomColor()
                x.label = this.props.allBenchmarks[this.props.inputData.benchmark].fund_name
                x.data.push(val * 100)
                chartData.labels.push(key)
            }
        }
        if (!isExcess && !serverData['fund_return'][this.props.inputData.benchmark]) {
            chartData.datasets.push(x)
        }

        this.setState({ chartData, loading: false })
    }
    render() {
        if (this.state.isError) {
            return <h3 style={{ margin: "20px" }}>No Data Available for selected Fund or Filter</h3>
        }
        return (
            this.state.loading ?
                <Box sx={{ margin: "20px", }}>
                    <Skeleton variant="rectangular" height='20px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='30px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='500px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='70px' width='100%' />
                </Box> :
                <Bar data={this.state.chartData}
                    plugins={[this.props.logoDraw]}
                    options={{
                        scales: {
                            x: {
                                title: {
                                    display: true, text: 'Time',
                                    font: {
                                        size: 20,
                                        weight: 25
                                    },
                                },
                                ticks: {
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                }
                            },
                            y: {
                                title: {
                                    display: true, text: 'Return % pa',
                                    font: {
                                        size: 20,
                                        weight: 25
                                    }
                                },
                                ticks: {
                                    callback: function (value, index, values) {
                                        return parseFloat(value).toFixed(2) + '%';
                                    },
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                }
                            }
                        },
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        const label = `${context.dataset.label}`
                                        return label;
                                    },
                                    title: function (context) {
                                        return `${context[0].label}, ${parseFloat(context[0].formattedValue).toFixed(2)}%`
                                    },

                                },
                                bodyFont: {
                                    size: 15
                                },
                                titleFont: {
                                    size: 15
                                }
                            },
                            legend: {
                                labels: {
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                },
                                position: 'bottom'
                            },
                            title: {
                                display: true,
                                text: [`Period Return Chart`, `${this.fund_name} Period ${this.props.inputData.excessReturn ? 'excess ' : ''}returns as at ${moment(this.props.inputData.endDate).format('MMM DD, YYYY')}`, `against ${this.benchmark_name}`],
                                font: {
                                    size: 18,
                                    weight: 25
                                }
                            }
                        }
                    }} />
        )
    }
}
const mapStateToProps = (state) => ({
    allFunds: state.allFunds.funds,
    allBenchmarks: state.allBenchmarks.benchmarks
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodReturnChart)
