import moment from 'moment';
import { subtractMoment } from '../../utils/utils'

export const selectedFundsReducer = (state = [], action) => {
    if (action.type === 'SELECTED_FUNDS') {
        return action.payload;
    }
    return state;
}

export const selectedFundsViewReducer = (state = {}, action) => {
    if (action.type === 'SELECTED_FUNDS_VIEW') {
        return action.payload;
    }
    return state
}

export const selectedFiltersReducer = (state = {
    // endDate: subtractMoment(0, moment(), 'year'),
    // startDate: subtractMoment(1, moment(), 'year'),
    endDate: subtractMoment(0, moment().subtract(1, 'years'), 'years'),
    startDate: subtractMoment(1, moment().subtract(1, 'years'), 'years'),
    period: '12',
    chartNumber: 1,
    isChartShown: false,
    excessReturn: false,
    showTable: false,
    rollingReturn: '1Y Rolling',
    portfolio: false,
    assetClassId: '',
    assetClass: '',
    segment: '',
    segmentId: '',
    portfolioAmt: 10000,
    rebalance: 0,
    weights: {},
    assetClassList: [],
    segmentList: []
}, action) => {
    if (action.type === 'SELECTED_FILTERS') {
        return { ...state, ...action.payload }
    }
    return state;
}

export const showViewReducer = (state = {
    startDataView: true,
    periodView: true,
    benchmarkView: true,
    excessReturnView: true,
    rollingReturnView: false,
    showTableView: true,
    portfolioAssetSegmentView: false,

    searchFundsOpen: true,
    searchChartsOpen: false,
    chartSettingsOpen: false,
    selectedFundsOpen: false,
    searchResultsOpen: true,
}, action) => {
    if (action.type === 'SHOW_VIEW') {
        return { ...state, ...action.payload }
    }
    return state;
}

export const assetSegmentListReducer = (state = {}, action) => {
    if (action.type === 'ASSET_SEGMENT_LIST') {
        return action.payload
    }
    return state
}