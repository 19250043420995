import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux"
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from './store/reducers'

import App from "./App"

import './index.css'

let store;
if (process.env.NODE_ENV === "development") store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
else store = createStore(reducers, applyMiddleware(thunk));
ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById("root"));


