import axios from 'axios'

let url;
if (process.env.NODE_ENV === 'development') {
    url = 'http://localhost:8000'
}
else if (process.env.NODE_ENV === 'production') {
    // url = 'https://mmi-quant-backend.herokuapp.com'
    url = 'https://mmi-quant-backend.onrender.com'
}

const api = axios.create({
    baseURL: url,
    headers: {
        // Authorization: process.env.REACT_APP_KEY
    }
})

export default api
