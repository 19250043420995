import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';

const renderInputJSX = (params) => {

  return (
    <TextField
      {...params}
      label="Type a Fund Name"


      InputProps={{
        ...params.InputProps,
        type: 'search',

      }}
    />);

}

const renderOptionJSX = (props, option) => {
  props.key = option.fund_id;
  return (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      {option.fund_name}
    </Box>
  );

}

export default function FreeSolo(props) {

  //dejavalue=props.fundName;
  return (

    <Autocomplete
      fullWidth
      // freeSolo
      className="searchpanel-dropdown"
      id="free-solo-2-demo"
      options={Object.values(props.allFunds || "")}
      getOptionLabel={option => option.fund_name || ""}
      renderOption={(props, option) => renderOptionJSX(props, option)}
      renderInput={(params) => renderInputJSX(params)}
      onChange={(event, value, reason, details) => props.onAutoCompleteChange(event, value, reason, details)}
      onInputChange={(event, value, reason) => props.onFundNameInputChange(event, value, reason)}
      disableClearable={false}


    />

  );
}

