import React, { Component } from 'react'

import List from "@mui/material/List";

import SearchFunds from './SearchFunds';
import SearchCharts from './SearchCharts';
import ChartSettings from './ChartSettings';


class LeftPanel extends Component {

    render() {
        return (
            <List sx={{
                width: "320px", maxHeight: '100vh',
                // boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                borderRight: '1px solid #d0d3d5',
                position: 'relative',
                overflow: 'auto',
            }}
                component="div"
                aria-labelledby="nested-list-subheader">
                <SearchFunds />
                <SearchCharts handleChartSelection={this.props.handleChartSelection} />
                <ChartSettings onChangePortfolio={this.props.onChangePortfolio} handleShowCharts={this.props.handleShowCharts} />
            </List >
        )
    }
}


export default LeftPanel
