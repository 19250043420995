/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Scatter } from 'react-chartjs-2';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import api from '../../config/api';
import moment from 'moment'
import { connect } from 'react-redux';
import { Chart } from 'chart.js';
import { subtractMoment } from '../../utils/utils';

class SnailTrailChart extends Component {
    state = {
        data: [],
        chartData: {
            labels: [],
            datasets: [],
        },
        loading: true,
        isError: false
    }

    componentDidMount = async () => {
        this.max_x = Number.MIN_SAFE_INTEGER;
        this.max_y = Number.MIN_SAFE_INTEGER;
        this.min_x = Number.MAX_SAFE_INTEGER;
        this.min_y = Number.MAX_SAFE_INTEGER;

        console.log("Snail Trail Chart: componentDidMount");
        try {
            let data
            if (this.props.inputData.portfolio) {
                const { startDate, endDate, portfolioAmt, rebalance, weights, benchmark } = this.props.inputData
                data = await api.get(`/portfolio/charts/snail-trail-chart`, {
                    params: {
                        start: startDate,
                        end: endDate,
                        amount: portfolioAmt,
                        rebalance: rebalance,
                        weights: weights,
                        benchmarkId: benchmark
                    }
                })

            }
            else {
                if (!this.props.inputData.excessReturn) {
                    data = await api.get(`/charts/snail-trail-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}&funds=${JSON.stringify(this.props.inputData.funds)}`)
                }
                else {
                    data = await api.get(`/charts/snail-trail-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}&benchmarkId=${this.props.inputData.benchmark}&funds=${JSON.stringify(this.props.inputData.funds)}`)
                }
            }
            this.setState({ data: data.data, isError: false })
            this.createChartData()
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }

    }

    componentDidUpdate = async (prevProp) => {
        console.log("Snail Trail Chart: componentDidUpdate");
        try {
            if (JSON.stringify(prevProp.inputData) !== JSON.stringify(this.props.inputData)) {
                this.max_x = Number.MIN_SAFE_INTEGER;
                this.max_y = Number.MIN_SAFE_INTEGER;
                this.min_x = Number.MAX_SAFE_INTEGER;
                this.min_y = Number.MAX_SAFE_INTEGER;

                this.setState({ loading: true })
                console.log("Snail Trail Chart" + this.state.loading);
                let data
                if (this.props.inputData.portfolio) {
                    const { startDate, endDate, portfolioAmt, rebalance, weights, benchmark } = this.props.inputData
                    data = await api.get(`/portfolio/charts/snail-trail-chart`, {
                        params: {
                            start: startDate,
                            end: endDate,
                            amount: portfolioAmt,
                            rebalance: rebalance,
                            weights: weights,
                            benchmarkId: benchmark
                        }
                    })

                }
                else {
                    if (!this.props.inputData.excessReturn) {
                        data = await api.get(`/charts/snail-trail-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}&funds=${JSON.stringify(this.props.inputData.funds)}`)
                    }
                    else {
                        data = await api.get(`/charts/snail-trail-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}&benchmarkId=${this.props.inputData.benchmark}&funds=${JSON.stringify(this.props.inputData.funds)}`)
                    }
                }
                this.setState({ data: data.data, isError: false })
                this.createChartData()
            }
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }
    }

    fund_name = ""
    benchmark_name = ""

    last_x = 0
    last_y = 0

    createChartData = () => {
        const { rollingReturn } = this.props.inputData
        const serverData = this.state.data

        if (this.props.inputData.portfolio) {
            this.fund_name = 'Portfolio'
        }
        else this.fund_name = this.props.allFunds[this.props.inputData.fundID].fund_name

        this.benchmark_name = this.props.inputData.excessReturn ? this.props.allBenchmarks[this.props.inputData.benchmark].fund_name : ''

        const borderColor = [
            '#269f64',
            '#1fb4d5',
            '#002850',
            '#3a6bfa',
            '#833e80',
        ]
        const backgroundColor = [
            '#269f6480',
            '#1fb4d580',
            '#00285080',
            '#3a6bfa80',
            '#833e8080',
        ]

        let chartData = {
            datasets: [
                // {
                //     label: this.fund_name,
                //     fill: false,
                //     backgroundColor: '#1fb4d5',
                //     borderColor: '#1fb4d580',
                //     data: [],
                //     pointRadius: 2,
                //     pointHoverRadius: 5,
                //     pointStyle: 'circle',
                //     showLine: true
                // }
            ]
        }

        if (rollingReturn === '1Y Rolling') {
            if (Object.values(serverData['one_year']).length === 0) {
                this.setState({ isError: true })
                return
            }
            let counter = 0
            for (const key in serverData['one_year']) {
                if (Object.hasOwnProperty.call(serverData['one_year'], key)) {
                    const element = serverData['one_year'][key];
                    const x = {
                        label: this.fund_name,
                        fill: false,
                        backgroundColor: '#1fb4d5',
                        borderColor: '#1fb4d580',
                        data: [],
                        pointRadius: 2,
                        pointHoverRadius: 5,
                        pointStyle: 'circle',
                        showLine: true
                    }

                    element.forEach(v => {
                        x.data.push({
                            x: v.standard_deviation * 100,
                            y: v.value * 100,
                            month_year: moment(v.month_year).format('MMM-YYYY').toString()
                        })
                        this.max_x = Math.max(this.max_x, v.standard_deviation * 100)
                        this.min_x = Math.min(this.min_x, v.standard_deviation * 100)
                        this.max_y = Math.max(this.max_y, v.value * 100)
                        this.min_y = Math.min(this.min_y, v.value * 100)
                    })
                    x.label = this.props.allFunds[key] ? this.props.allFunds[key].fund_name : 'Portfolio'
                    x.borderColor = borderColor[counter]
                    x.backgroundColor = backgroundColor[counter]
                    chartData.datasets.push(x)
                    counter++
                }
            }
        }

        else if (rollingReturn === '3Y Rolling') {
            if (Object.values(serverData['three_year']).length === 0) {
                this.setState({ isError: true })
                return
            }
            let counter = 0
            for (const key in serverData['three_year']) {
                if (Object.hasOwnProperty.call(serverData['three_year'], key)) {
                    const element = serverData['three_year'][key];
                    const x = {
                        label: this.fund_name,
                        fill: false,
                        backgroundColor: '#1fb4d5',
                        borderColor: '#1fb4d580',
                        data: [],
                        pointRadius: 2,
                        pointHoverRadius: 5,
                        pointStyle: 'circle',
                        showLine: true
                    }

                    element.forEach(v => {
                        x.data.push({
                            x: v.standard_deviation * 100,
                            y: v.value * 100,
                            month_year: moment(v.month_year).format('MMM-YYYY').toString()
                        })
                        this.max_x = Math.max(this.max_x, v.standard_deviation * 100)
                        this.min_x = Math.min(this.min_x, v.standard_deviation * 100)
                        this.max_y = Math.max(this.max_y, v.value * 100)
                        this.min_y = Math.min(this.min_y, v.value * 100)
                    })
                    x.label = this.props.allFunds[key] ? this.props.allFunds[key].fund_name : 'Portfolio'
                    x.borderColor = borderColor[counter]
                    x.backgroundColor = backgroundColor[counter]
                    chartData.datasets.push(x)
                    counter++
                }
            }
        }
        else if (rollingReturn === '5Y Rolling') {
            if (Object.values(serverData['five_year']).length === 0) {
                this.setState({ isError: true })
                return
            }
            let counter = 0
            for (const key in serverData['five_year']) {
                if (Object.hasOwnProperty.call(serverData['five_year'], key)) {
                    const element = serverData['five_year'][key];
                    const x = {
                        label: this.fund_name,
                        fill: false,
                        backgroundColor: '#1fb4d5',
                        borderColor: '#1fb4d580',
                        data: [],
                        pointRadius: 2,
                        pointHoverRadius: 5,
                        pointStyle: 'circle',
                        showLine: true
                    }

                    element.forEach(v => {
                        x.data.push({
                            x: v.standard_deviation * 100,
                            y: v.value * 100,
                            month_year: moment(v.month_year).format('MMM-YYYY').toString()
                        })
                        this.max_x = Math.max(this.max_x, v.standard_deviation * 100)
                        this.min_x = Math.min(this.min_x, v.standard_deviation * 100)
                        this.max_y = Math.max(this.max_y, v.value * 100)
                        this.min_y = Math.min(this.min_y, v.value * 100)
                    })
                    x.label = this.props.allFunds[key] ? this.props.allFunds[key].fund_name : 'Portfolio'
                    x.borderColor = borderColor[counter]
                    x.backgroundColor = backgroundColor[counter]
                    chartData.datasets.push(x)
                    counter++
                }
            }
        }

        // else if (rollingReturn === '3Y Rolling') {
        //     if (serverData['three_year'].length === 0) {
        //         this.setState({ isError: true })
        //         return
        //     }
        //     serverData['three_year'].forEach(v => {
        //         chartData.datasets[0].data.push({
        //             x: v.standard_deviation * 100,
        //             y: v.value * 100,
        //             month_year: moment(v.month_year).format('MMM-YYYY').toString()
        //         })
        //         this.max_x = Math.max(this.max_x, v.standard_deviation * 100)
        //         this.min_x = Math.min(this.min_x, v.standard_deviation * 100)
        //         this.max_y = Math.max(this.max_y, v.value * 100)
        //         this.min_y = Math.min(this.min_y, v.value * 100)
        //     })
        // }
        // else if (rollingReturn === '5Y Rolling') {
        //     if (serverData['five_year'].length === 0) {
        //         this.setState({ isError: true })
        //         return
        //     }
        //     serverData['five_year'].forEach(v => {
        //         chartData.datasets[0].data.push({
        //             x: v.standard_deviation * 100,
        //             y: v.value * 100,
        //             month_year: moment(v.month_year).format('MMM-YYYY').toString()
        //         })
        //         this.max_x = Math.max(this.max_x, v.standard_deviation * 100)
        //         this.min_x = Math.min(this.min_x, v.standard_deviation * 100)
        //         this.max_y = Math.max(this.max_y, v.value * 100)
        //         this.min_y = Math.min(this.min_y, v.value * 100)
        //     });
        // }

        this.setState({ chartData, loading: false })

    }

    getDataForLogo = () => {
        const res = {
            first_x: [],
            first_y: [],
            last_x: [],
            last_y: []
        }
        const { rollingReturn } = this.props.inputData
        const serverData = this.state.data

        if (serverData.length === 0) return res

        if (rollingReturn === '1Y Rolling' && Object.values(serverData['one_year']).length > 0) {
            for (const key in serverData['one_year']) {
                if (Object.hasOwnProperty.call(serverData['one_year'], key)) {
                    const element = serverData['one_year'][key];
                    res.first_x.push(element[element.length - 1].standard_deviation)
                    res.first_y.push(element[element.length - 1].value)
                    res.last_x.push(element[0].standard_deviation)
                    res.last_y.push(element[0].value)
                }
            }
        }
        else if (rollingReturn === '3Y Rolling' && Object.values(serverData['three_year']).length > 0) {
            for (const key in serverData['three_year']) {
                if (Object.hasOwnProperty.call(serverData['three_year'], key)) {
                    const element = serverData['three_year'][key];
                    res.first_x.push(element[element.length - 1].standard_deviation)
                    res.first_y.push(element[element.length - 1].value)
                    res.last_x.push(element[0].standard_deviation)
                    res.last_y.push(element[0].value)
                }
            }
        }
        else if (rollingReturn === '5Y Rolling' && Object.values(serverData['five_year']).length > 0) {
            for (const key in serverData['five_year']) {
                if (Object.hasOwnProperty.call(serverData['five_year'], key)) {
                    const element = serverData['five_year'][key];
                    res.first_x.push(element[element.length - 1].standard_deviation)
                    res.first_y.push(element[element.length - 1].value)
                    res.last_x.push(element[0].standard_deviation)
                    res.last_y.push(element[0].value)
                }
            }
        }
        // else if (rollingReturn === '3Y Rolling' && serverData['three_year'].length > 0) {
        //     res.first_x = serverData['three_year'][serverData['three_year'].length - 1].standard_deviation
        //     res.first_y = serverData['three_year'][serverData['three_year'].length - 1].value

        //     res.last_x = serverData['three_year'][0].standard_deviation
        //     res.last_y = serverData['three_year'][0].value
        // }
        // else if (rollingReturn === '5Y Rolling' && serverData['five_year'].length > 0) {
        //     res.first_x = serverData['five_year'][serverData['five_year'].length - 1].standard_deviation
        //     res.first_y = serverData['five_year'][serverData['five_year'].length - 1].value

        //     res.last_x = serverData['five_year'][0].standard_deviation
        //     res.last_y = serverData['five_year'][0].value
        // }

        return res
    }

    render() {
        const { first_x, first_y, last_x, last_y } = this.getDataForLogo()
        const { rollingReturn } = this.props.inputData
        var logo = new Image();
        logo.src = 'https://www.moneymanagementindia.net/wp-content/uploads/mmi-logo-png-resized.png';

        var start = new Image()
        var end = new Image()
        start.src = 'S.jpg'
        end.src = 'E.jpg'

        const logoDraw = {
            id: 'logoDraw',
            beforeDraw: function (chart) {
                const { ctx, chartArea: { top, bottom, left, right } } = chart
                ctx.save()
                // ctx.globalAlpha = 0.6
                if (logo.complete) {
                    ctx.drawImage(logo, left, 0, 135, 40);
                    // ctx.drawImage(logo, left, top, 135, 40);
                    // ctx.drawImage(logo, right - 135, 0, 135, 40);
                }
                else {
                    logo.onload = () => chart.draw()
                }
                // ctx.textBaseline = "middle";

                // var text = "*Source - Money Management India with data from Crisil",
                //     textX = 10,
                //     textY = bottom + 30;

                // ctx.fillStyle = '#666666'

                // ctx.fillText(text, textX, textY);

                ctx.restore()
            },
            afterDatasetDraw: (chart) => {
                const { ctx, chartArea: { top, bottom, left, right }, scales: { x, y } } = chart
                ctx.save()
                if (start.complete) {
                    for (let i = 0; i < first_x.length; i++) {
                        ctx.drawImage(start, x.getPixelForValue(last_x[i] * 100) - 7.5, y.getPixelForValue(last_y[i] * 100) - 7.5, 15, 15);
                    }
                }
                else {
                    start.onload = () => chart.draw()
                }

                if (end.complete) {
                    for (let i = 0; i < first_x.length; i++) {
                        ctx.drawImage(end, x.getPixelForValue(first_x[i] * 100) - 7.5, y.getPixelForValue(first_y[i] * 100) - 7.5, 15, 15);
                    }
                }
                else {
                    end.onload = () => chart.draw()
                }

            }
        }
        if (this.state.isError) {
            return <h3 style={{ margin: "20px" }}>No Data Available for selected Fund or Filter</h3>
        }

        let period
        if (this.props.inputData.period) {
            const p = parseInt(this.props.inputData.period)
            if (p > 12) period = `${p} months`
            else period = `${p / 12} years`
        }

        return (
            this.state.loading ?
                <Box sx={{ margin: "20px ", }}>
                    <Skeleton variant="rectangular" height='20px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='30px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='500px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='70px' width='100%' />
                </Box> :

                <Scatter data={this.state.chartData}
                    plugins={[logoDraw]}
                    options={{
                        scales: {
                            x: {
                                title: {
                                    display: true, text: 'Risk',
                                    font: {
                                        size: 20,
                                        weight: 25
                                    }
                                },
                                ticks: {
                                    callback: function (value, index, values) {
                                        return parseFloat(value).toFixed(2) + '%';
                                    },
                                    beginAtZero: true,
                                    font: {
                                        size: 15,
                                        weight: 25
                                    },
                                },
                                suggestedMax: Math.max(Math.abs(this.max_x), Math.abs(this.min_x)),
                                suggestedMin: Math.max(Math.abs(this.max_x), Math.abs(this.min_x)) * -1,
                                position: 'center'
                            },
                            y: {
                                title: {
                                    display: true, text: 'Return % pa',
                                    font: {
                                        size: 20,
                                        weight: 25
                                    }
                                },
                                ticks: {
                                    callback: function (value, index, values) {
                                        return parseFloat(value).toFixed(2) + '%';
                                    },
                                    beginAtZero: true,
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                },
                                suggestedMax: Math.max(Math.abs(this.max_y), Math.abs(this.min_y)),
                                suggestedMin: Math.max(Math.abs(this.max_y), Math.abs(this.min_y)) * -1,
                                position: 'center'
                            }
                        },
                        plugins: {
                            customPoint: true,
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        var label = context.dataset.label || '';
                                        if (label) {
                                            label = 'Fund : ' + label;
                                        }
                                        return label;
                                    },
                                    afterBody: function (context) {
                                        let label = [`Risk: ${context[0].label}%`]
                                        label.push(`${rollingReturn} Return: ${parseFloat(context[0].formattedValue).toFixed(2)}%`)
                                        return label;
                                    },
                                    title: function (context) {
                                        const label = `Month Year: ${context[0].raw.month_year}`
                                        return label;
                                    },
                                },
                                bodyFont: {
                                    size: 15
                                },
                                titleFont: {
                                    size: 15
                                },
                                displayColors: true
                            },
                            legend: {
                                labels: {
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                },
                                position: 'bottom'
                            },
                            title: {
                                display: true,
                                text: [`Snail Trail Chart`,
                                    `${this.fund_name} ${this.props.inputData.rollingReturn} ${this.props.inputData.excessReturn ? 'excess risk/return' : 'risk/return'}`,
                                    `against ${this.benchmark_name} ${period ? `over ${period}` : `from ${moment(subtractMoment(0, this.props.inputData.startDate, 'months')).format('MMM DD, YYYY')}`} to ${moment(subtractMoment(0, this.props.inputData.endDate, 'months')).format('MMM DD, YYYY')}`],
                                font: {
                                    size: 18,
                                    weight: 25
                                }
                            },
                        }
                    }
                    } />
        )
    }
}

const mapStateToProps = (state) => ({
    allFunds: state.allFunds.funds,
    allBenchmarks: state.allBenchmarks.benchmarks
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SnailTrailChart)