import axios from 'axios';
import Cookies from 'js-cookie';
const authenticateUser = async () => {
    // userIDEnc b11457ea556f463178dba0cc7ff3a62c
    // userID 646
    return "646"
    try {
        console.log("authenticate enter");
        const userID = await Cookies.get('userID');
        const userIDEnc = await Cookies.get('userIDEnc');
        const response = await axios.post(
            "https://moneymanagementindia.net/wp-json/checkuserauth/verify",
            {
                userID,
                userIDEnc
            }
        );


        if (response.status !== 200) throw new Error("Network Error");
        if (response.data && response.data.status && response.data.status === true) {
            console.log("login successful");
            return userID;
        }
        throw new Error({ type: "userNotVerified", message: "User Not logged IN" });

    } catch (err) {
        console.log(err);
        // alert("User Not logged In");
        window.location.href = "https://www.moneymanagementindia.net/login/?redirect_to=https://quant.moneymanagementindia.net/";

    }
}
export default authenticateUser;
