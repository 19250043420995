import React, { Component } from 'react'
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import api from '../../config/api';
import moment from 'moment'
import { Box, Skeleton } from '@mui/material';

class GrowthChart extends Component {
    state = {
        data: [],
        chartData: {
            labels: [],
            datasets: []
        },
        loading: true,
        isError: false

    }

    componentDidMount = async () => {
        console.log("Growth Chart: componentDidMount");
        let data
        try {
            if (this.props.inputData.portfolio) {
                const { startDate, endDate, portfolioAmt, rebalance, weights } = this.props.inputData
                data = await api.get(`/portfolio/charts/growth-chart`, {
                    params: {
                        start: startDate,
                        end: endDate,
                        amount: portfolioAmt,
                        rebalance: rebalance,
                        weights: weights
                    }
                })

            }
            else {
                if (this.props.inputData.funds.length === 0) {
                    data = await api.get(`/charts/growth-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}`)
                }
                else {
                    data = await api.get(`/charts/growth-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}&funds=${JSON.stringify(this.props.inputData.funds)}`)
                }
            }

            this.setState({ data: data.data, isError: false })
            this.createChartData()
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.error(e);
        }

    }

    componentDidUpdate = async (prevProp) => {
        console.log("Growth Chart: componentDidUpdate");
        let data
        try {
            if (JSON.stringify(prevProp.inputData) !== JSON.stringify(this.props.inputData)) {
                if (this.props.inputData.portfolio) {
                    const { startDate, endDate, portfolioAmt, rebalance, weights } = this.props.inputData
                    data = await api.get(`/portfolio/charts/growth-chart`, {
                        params: {
                            start: startDate,
                            end: endDate,
                            amount: portfolioAmt,
                            rebalance: rebalance,
                            weights: weights
                        }
                    })

                }
                else {
                    if (this.props.inputData.funds.length === 0) {
                        data = await api.get(`/charts/growth-chart/${this.props.inputData.fundID}?start = ${this.props.inputData.startDate}& end=${this.props.inputData.endDate} `)
                    }
                    else {
                        data = await api.get(`/charts/growth-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}&funds=${JSON.stringify(this.props.inputData.funds)}`)
                    }
                }
                this.setState({ data: data.data, isError: false })
                this.createChartData()
            }
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }
    }

    createChartData = () => {
        const serverData = this.state.data
        let chartData = {
            labels: [],
            datasets: []
        }
        const borderColor = [
            '#269f64',
            '#1fb4d5',
            '#002850',
            '#3a6bfa',
            '#833e80',
        ]
        const backgroundColor = [
            '#269f6480',
            '#1fb4d580',
            '#00285080',
            '#3a6bfa80',
            '#833e8080',
        ]
        let counter = 0

        for (const key in serverData) {
            if (Object.hasOwnProperty.call(serverData, key)) {
                let x = {
                    label: '',
                    fill: false,
                    backgroundColor: 'rgb(54, 162, 235)',
                    borderColor: 'rgb(54, 162, 235)',
                    data: [],
                    pointRadius: 2,
                    pointHoverRadius: 5,
                    tension: 0.2
                }
                const element = serverData[key];
                // eslint-disable-next-line no-loop-func
                element.forEach(val => {
                    x.data.push(val.index_value)
                });
                x.label = this.props.allFunds[key] ? this.props.allFunds[key].fund_name : 'Portfolio'
                x.backgroundColor = `${backgroundColor[counter]} `
                x.borderColor = `${borderColor[counter]} `
                chartData.datasets.push(x)
                counter += 1
            }
        }

        Object.values(serverData)[0].forEach(val => {
            let month = moment(val.month_year).format('MMM-YYYY').toString()
            chartData.labels.push(month)
        });

        this.setState({ chartData, loading: false })
    }
    render() {
        if (this.state.isError) {
            return <h3 style={{ margin: "20px" }}>No Data Available for selected Fund or Filter</h3>
        }
        return (
            this.state.loading ?
                <Box sx={{ margin: "20px", }}>
                    <Skeleton variant="rectangular" height='20px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='30px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='500px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='70px' width='100%' />
                </Box> :
                <Line data={this.state.chartData}
                    plugins={[this.props.logoDraw]}
                    options={{
                        scales: {
                            x: {
                                title: {
                                    display: true, text: 'Time',
                                    font: {
                                        size: 20,
                                        weight: 25
                                    }
                                },
                                ticks: {
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                }
                            },
                            y: {
                                title: {
                                    display: true, text: 'Amount',
                                    font: {
                                        size: 20,
                                        weight: 25
                                    }
                                },
                                ticks: {
                                    callback: function (value, index, values) {
                                        return value.toLocaleString("en-US", {
                                            style: "currency", currency: "INR", minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        });
                                    },
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                }
                            }
                        },
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        const label = context.dataset.label;
                                        return label;
                                    },
                                    title: function (context) {
                                        return `${context[0].label},  ${context[0].raw.toLocaleString("en-US", {
                                            style: "currency", currency: "INR",
                                        })
                                            } `
                                    },

                                },
                                bodyFont: {
                                    size: 15
                                },
                                titleFont: {
                                    size: 15
                                }
                            },
                            legend: {
                                labels: {
                                    font: {
                                        size: 15,
                                        weight: 25
                                    },
                                },
                                position: 'bottom'
                            },
                            title: {
                                display: true,
                                text: 'Growth Chart of ₹10,000',
                                font: {
                                    size: 18,
                                    weight: 25
                                }
                            }
                        }
                    }} />
        )
    }
}

const mapStateToProps = (state) => ({
    allFunds: state.allFunds.funds
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(GrowthChart)

