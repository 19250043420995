export const selectedFundsAction = (selectedFunds) => {
    return {
        type: 'SELECTED_FUNDS',
        payload: selectedFunds
    }
}

export const selectedFiltersAction = (selectedFilters) => {
    return {
        type: 'SELECTED_FILTERS',
        payload: selectedFilters
    }
}

export const showViewAction = (selectedFilters) => {
    return {
        type: 'SHOW_VIEW',
        payload: selectedFilters
    }
}

export const assetSegmentListAction = (list) => {
    return {
        type: 'ASSET_SEGMENT_LIST',
        payload: list
    }
}

export const selectedFundsViewAction = (list) => {
    return {
        type: 'SELECTED_FUNDS_VIEW',
        payload: list
    }
}