import React, { Component } from 'react'

import Box from '@mui/material/Box';
import FundTable from './FundTable';
import { Collapse, ListItem, ListItemButton, ListItemText, Switch, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchButton from '../LeftPanel/SearchButton';

class SearchResultPanel extends Component {
    state = {
        portfolio: false
    }
    render() {
        const { filters } = this.props
        return (
            <Box sx={{ flexGrow: 1, margin: "20px", backgroundColor: 'white', borderRadius: '5px' }}>
                <ListItem>
                    <ListItemButton disableTouchRipple onClick={() => this.props.toggleOpen(this.props.title)} >
                        <ListItemText primary={this.props.title}
                            primaryTypographyProps={{
                                fontWeight: 'bold',
                                letterSpacing: 0,
                            }} />
                        {this.props.openState ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    {this.props.title === 'SELECTED FUNDS' &&
                        <>
                            <Typography variant='h6'>Portfolio</Typography>
                            <Switch sx={{ marginRight: '1rem' }}
                                onChange={(e) => {
                                    this.setState({ portfolio: e.target.checked })
                                    this.props.onChangePortfolio(e.target.checked, 'portfolio')
                                }}
                                checked={filters.portfolio}
                            />
                            <SearchButton onSearchButtonClick={this.props.handleShowCharts} title='Show Charts' />
                            <SearchButton sx={{ marginRight: '1rem' }} onSearchButtonClick={this.props.handleClearAll} title='Clear All' />
                        </>

                    }
                </ListItem>
                <Collapse in={this.props.openState} timeout="auto" unmountOnExit={true}>
                    <FundTable rows={this.props.searchResults}
                        onFundItemClicked={this.onFundItemClicked}
                        isSelected={this.props.isSelected}
                        handleFundsSelected={this.props.handleFundsSelected}
                        title={this.props.title}
                        filters={filters}
                        onChangePortfolio={this.props.onChangePortfolio} />
                </Collapse>
            </Box >
        )
    }
}

export default SearchResultPanel

