import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux'

import ErrorBoundary from './components/ErrorBoundary'
import WindowSizeNotCompatible from './components/WindowSizeIsNotCompatible'
import Header from './components/Header'
import MainPage from './components/MainPage'
import { actions } from './store/actions'

class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            windowWidth: window.innerWidth
        }
        window.addEventListener('resize', this.handleWindowResize);
        this.props.setUserData()
    }

    handleWindowResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    getJSX = (windowWidth, userData) => {
        if (windowWidth <= 500)
            return <WindowSizeNotCompatible />;
        return (
            <Router basename={process.env.PUBLIC_URL}>
                <Header />
                {process.env.NODE_ENV === 'development' ?
                    <MainPage /> :
                    < ErrorBoundary >
                        <MainPage />
                    </ErrorBoundary>
                }
            </Router>

        );
    }

    render() {
        return (
            <div>
                {this.getJSX(this.state.windowWidth, this.props.userData)}
            </div>
        )
    }
}
const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    setUserData: actions.setUserDataAction
}


export default connect(mapStateToProps, mapDispatchToProps)(App)


