import React from "react";


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error("from error boundary");
        console.error(error, errorInfo);

    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{ marginTop: "1rem", widht: "100vw", textAlign: "center" }}>
                    <img
                        src={"https://cdn.dribbble.com/users/463734/screenshots/2016799/generic-error_shot.png?compress=1&resize=800x600"}
                        alt=""
                    >
                    </img>
                </div>



            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;