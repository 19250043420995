import { Box, Typography } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import LeftPanel from '../LeftPanel'
import SearchResultPanel from '../SearchResultPanel'
import { actions } from '../../store/actions'
import GrowthChart from '../charts/GrowthChart'
import PeriodReturnChart from '../charts/PeriodReturnChart'
import RiskReturnChart from '../charts/RiskReturnChart'
import SnailTrailChart from '../charts/SnailTrailChart'
import TrackingError from '../charts/TrackingError'
import NominalIR from '../charts/NominalIR'
import RollingReturnChart from '../charts/RollingReturnChart'
import QuartileChart from '../charts/QuartileChart'
import PeriodReturnTable from './PeriodReturnTable'

class MainPage extends Component {

    state = {
        isChartShown: false,
        chartNumber: 1,
        segmentList: [],
        errorMsg: '',
        showError: false
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.searchResults !== this.props.searchResults) {
            this.setState({ isChartShown: false })
        }
        if (prevProps.allFunds !== this.props.allFunds) {
            const assetClassList = []
            const assetSegmentList = {}
            if (this.props.allFunds && Object.keys(this.props.allFunds).length !== 0) {

                for (const key in this.props.allFunds) {
                    if (Object.hasOwnProperty.call(this.props.allFunds, key)) {
                        const element = this.props.allFunds[key];
                        const segment = assetSegmentList[element['asset_class_name']] ? assetSegmentList[element['asset_class_name']]['segment'] : {}
                        assetSegmentList[element['asset_class_name']] = { id: element['asset_class_id'], segment: { ...segment, [element['segment_id']]: { name: element['segment_name'], id: element['segment_id'] } } }
                    }
                }
            }
            for (const key in assetSegmentList) {
                if (Object.hasOwnProperty.call(assetSegmentList, key)) {
                    const ele = assetSegmentList[key];
                    assetSegmentList[key]['segment'] = Object.values(ele.segment).sort((a, b) => a.name < b.name ? -1 : 1)
                    assetClassList.push({ name: key, id: ele.id })
                }
            }
            this.props.setAssetSegment(assetSegmentList)
            this.props.setFilters({ assetClassList })
        }
    }

    isSelected = (fundId) => {
        return (!!this.props.selectedFundsView[fundId] && this.props.selectedFundsView[fundId].visible)
    }

    handleFundsSelected = (e, fund, flag) => {
        const selected = [...this.props.selected];
        const index = selected.findIndex(v => v.fund_id === fund.fund_id)
        const selectedFundsView = { ...this.props.selectedFundsView }
        const weights = this.props.filters.weights

        if (flag === 'delete') {
            if (selectedFundsView[fund.fund_id].visible) {
                selected.splice(index, 1)
            }
            delete selectedFundsView[fund.fund_id]
            delete weights[fund.fund_id]
        }
        else if (selectedFundsView[fund.fund_id] && selectedFundsView[fund.fund_id].visible) {
            selectedFundsView[fund.fund_id].visible = false
            selected.splice(index, 1)

        }
        else {
            if (selectedFundsView[fund.fund_id]) {
                selectedFundsView[fund.fund_id].visible = true
            }
            else {
                selectedFundsView[fund.fund_id] = { visible: true, fund }
            }
            selected.unshift(fund)
        }


        this.props.setSelectedFunds(selected)
        this.props.setSelectedFundsView(selectedFundsView)
        if (!this.props.filters.portfolio) {
            this.props.setFilters({ weights })
        }
    }

    toggleOpen = (id) => {
        if (id === 'SELECTED FUNDS') {
            this.props.setView({ selectedFundsOpen: !this.props.showView.selectedFundsOpen })
        }
        else if (id === 'SEARCH RESULTS') {
            this.props.setView({ searchResultsOpen: !this.props.showView.searchResultsOpen })
        }
    }

    onChangePortfolio = (value, flag) => {
        if (flag === 'weights') {
            this.props.setFilters({ [flag]: { ...this.props.filters.weights, ...value } })
        }
        else if (flag === 'assetClassId') {
            this.props.setFilters({
                [flag]: value ? value.id : null,
                assetClass: value ? value.name : null,
                segmentList: value ? this.props.assetSegmentList[value.name].segment : []
            })
        }
        else if (flag === 'segmentId') {
            this.props.setFilters({ [flag]: value ? value.id : null, 'segment': value ? value.name : null })
        }
        else this.props.setFilters({ [flag]: value })
        if (flag === 'portfolio' && !this.state.isChartShown && value) {
            this.props.setView({ selectedFundsOpen: true, searchResultsOpen: false })
        }
    }

    handleClearAll = () => {
        this.props.setSelectedFundsView({})
        this.props.setSelectedFunds([])
        this.setState({ isChartShown: false })
        this.props.setFilters({ weights: {} })
        this.props.setView({ selectedFundsOpen: true, searchResultsOpen: true, searchFundsOpen: true, searchChartsOpen: false, chartSettingsOpen: false })
    }

    handleShowCharts = () => {
        const selected = this.props.selected;
        if (selected.length > 0) {
            this.setState({ isChartShown: true })
            this.handleChartSelection(this.state.chartNumber)
        }
    }

    handleChartSelection = (chartId) => {
        const selected = this.props.selected;
        if (selected.length === 0) return

        const filters = {}
        const showView = {}
        filters['fundID'] = selected[0].fund_id
        filters['benchmark'] = selected[0].default_benchmark_id || this.props.filters['benchmark']
        console.log('Chart selection handled', chartId);

        switch (chartId) {
            case 1: // Growth Chart
                filters['funds'] = selected.map(v => v.fund_id)
                showView['startDateView'] = true
                showView['periodView'] = true
                showView['benchmarkView'] = false
                showView['excessReturnView'] = false
                showView['rollingReturnView'] = false
                showView['showTableView'] = false
                showView['portfolioAssetSegmentView'] = false
                break;

            case 2: // Period Return
                filters['funds'] = selected.map(v => v.fund_id)
                showView['startDateView'] = false
                showView['periodView'] = false
                showView['benchmarkView'] = true
                showView['excessReturnView'] = true
                showView['rollingReturnView'] = false
                showView['showTableView'] = true
                showView['portfolioAssetSegmentView'] = false
                break;

            case 3: // Quartile Chart
                showView['startDateView'] = false
                showView['periodView'] = false
                showView['benchmarkView'] = true
                showView['excessReturnView'] = true
                showView['rollingReturnView'] = false
                showView['showTableView'] = false
                showView['portfolioAssetSegmentView'] = true
                break;

            case 4: // Risk Return
                showView['startDateView'] = false
                showView['periodView'] = true
                showView['benchmarkView'] = true
                showView['excessReturnView'] = true
                showView['rollingReturnView'] = false
                showView['showTableView'] = false
                showView['portfolioAssetSegmentView'] = true
                break

            case 5: // Rolling Return 
                showView['startDateView'] = true
                showView['periodView'] = true
                showView['benchmarkView'] = true
                showView['excessReturnView'] = true
                showView['rollingReturnView'] = true
                showView['showTableView'] = false
                showView['portfolioAssetSegmentView'] = true
                break

            case 6: // Snail Trail Chart
                filters['funds'] = selected.map(v => v.fund_id)
                showView['startDateView'] = true
                showView['periodView'] = true
                showView['benchmarkView'] = true
                showView['excessReturnView'] = false
                showView['rollingReturnView'] = true
                showView['showTableView'] = false
                filters['excessReturn'] = true
                showView['portfolioAssetSegmentView'] = false
                break

            case 7: // Tracking Error
                console.log(this.props.filters.portfolio);
                showView['startDateView'] = true
                showView['periodView'] = true
                showView['benchmarkView'] = true
                showView['excessReturnView'] = false
                showView['rollingReturnView'] = false
                showView['showTableView'] = false
                showView['portfolioAssetSegmentView'] = false
                break

            case 8: // Nominal IR
                showView['startDateView'] = true
                showView['periodView'] = true
                showView['benchmarkView'] = true
                showView['excessReturnView'] = false
                showView['rollingReturnView'] = false
                showView['showTableView'] = false
                showView['portfolioAssetSegmentView'] = false
                break

            default:
                break;
        }

        showView.searchFundsOpen = false
        showView.searchChartsOpen = true
        showView.chartSettingsOpen = true
        showView.selectedFundsOpen = false

        this.setState({ chartNumber: chartId })
        this.props.setFilters({ chartNumber: chartId, ...filters })
        this.props.setView({ ...showView })

    }

    createChartLogo = () => {
        var logo = new Image();
        logo.src = 'https://www.moneymanagementindia.net/wp-content/uploads/mmi-logo-png-resized.png';

        const logoDraw = {
            id: 'logoDraw',
            beforeDraw: function (chart) {
                // eslint-disable-next-line no-unused-vars
                const { ctx, chartArea: { top, bottom, left, right } } = chart
                ctx.save()
                // ctx.globalAlpha = 0.6
                if (logo.complete) {
                    ctx.drawImage(logo, left, 0, 135, 40);
                }
                else {
                    logo.onload = () => chart.draw()
                }
                // ctx.textBaseline = "middle";

                // var text = "*Source - Money Management India with data from Crisil",
                //     textX = 10,
                //     textY = bottom + 60;

                // ctx.fillStyle = '#666666'

                // ctx.fillText(text, textX, textY);

                ctx.restore()
            },
        }

        return logoDraw
    }

    renderChart = () => {
        const chart = this.state.chartNumber
        const inputData = { ...this.props.filters }

        if (inputData.portfolio && this.props.showView.portfolioAssetSegmentView && (!inputData.assetClass || !inputData.segment)) {
            return <Typography variant='h5' sx={{ margin: '20px' }} color='error'>*Please Select Asset Class and Segment from Chart Settings</Typography>
        }
        if (this.props.showView.benchmarkView && !inputData.benchmark) {
            return <Typography variant='h5' sx={{ margin: '20px' }} color='error'>*Please Select a Benchmark from Chart Settings</Typography>
        }
        if (inputData.portfolio) {
            let weightStatus = Object.values(this.props.filters.weights).length === this.props.selected.length
            Object.values(this.props.filters.weights).forEach((v) => {
                if (!v) {
                    weightStatus = false
                }
            })
            if (weightStatus) {
                const sum = Object.values(this.props.filters.weights).reduce((prev, curr) => prev + curr * 100, 0).toFixed(0)
                console.log(sum);
                if (sum !== (100.00).toFixed(0)) {
                    weightStatus = false
                }
            }

            if (!weightStatus) {
                return <Typography variant='h5' sx={{ margin: '20px' }} color='error'>*Please Select correct Weights for the Portfolio</Typography>
            }
        }

        switch (chart) {
            case 1:
                return <GrowthChart logoDraw={this.createChartLogo()} inputData={inputData} />

            case 2:
                return (
                    inputData.showTable ?
                        <PeriodReturnTable rows={this.props.searchResults} inputData={inputData} /> :
                        <PeriodReturnChart logoDraw={this.createChartLogo()} inputData={inputData} />
                )

            case 3:
                return <QuartileChart logoDraw={this.createChartLogo()} inputData={inputData} />

            case 4:
                return <RiskReturnChart logoDraw={this.createChartLogo()} inputData={inputData} />

            case 5:
                return <RollingReturnChart logoDraw={this.createChartLogo()} inputData={inputData} />

            case 6:
                return <SnailTrailChart logoDraw={this.createChartLogo()} inputData={inputData} />

            case 7:
                return <TrackingError logoDraw={this.createChartLogo()} inputData={inputData} />

            case 8:
                return <NominalIR logoDraw={this.createChartLogo()} inputData={inputData} />

            default:
                break;
        }
    }

    render() {
        return (
            <Box sx={{ display: 'flex', }}>
                <LeftPanel
                    handleChartSelection={this.handleChartSelection}
                    handleShowCharts={this.handleShowCharts}
                    onChangePortfolio={this.onChangePortfolio} />
                <Box sx={{ display: 'flex-reverse', flexGrow: 1 }}>
                    <SearchResultPanel
                        title='SELECTED FUNDS'
                        // searchResults={this.props.selected}
                        searchResults={
                            Object.values(this.props.selectedFundsView).reduce((prev, curr) => {
                                prev.push(curr.fund)
                                return prev
                            }, [])
                        }
                        handleClearAll={this.handleClearAll}
                        isSelected={this.isSelected}
                        handleFundsSelected={this.handleFundsSelected}
                        handleShowCharts={this.handleShowCharts}
                        openState={this.props.showView.selectedFundsOpen}
                        toggleOpen={this.toggleOpen}
                        assetClassList={this.props.filters.assetClassList}
                        segmentList={this.props.filters.segmentList}
                        onChangePortfolio={this.onChangePortfolio}
                        filters={this.props.filters}
                        allFunds={this.props.allFunds} />
                    {!this.state.isChartShown ?
                        <SearchResultPanel
                            title='SEARCH RESULTS'
                            searchResults={this.props.searchResults}
                            isSelected={this.isSelected}
                            handleFundsSelected={this.handleFundsSelected}
                            openState={this.props.showView.searchResultsOpen}
                            toggleOpen={this.toggleOpen} /> :
                        this.renderChart()
                    }
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        searchResults: state.searchResultList,
        selected: state.selectedFunds,
        filters: state.selectedFilters,
        showView: state.showView,
        allFunds: state.allFunds.funds,
        assetSegmentList: state.assetSegmentList,
        selectedFundsView: state.selectedFundsView
    }
}

const mapDispatchToProps = {
    setSelectedFunds: actions.selectedFundsAction,
    setFilters: actions.selectedFiltersAction,
    setView: actions.showViewAction,
    setAssetSegment: actions.assetSegmentListAction,
    setSelectedFundsView: actions.selectedFundsViewAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
