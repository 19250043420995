import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import './search_input.css'

const SelectInput = (props) => {

  return (
    // <div >
    //   <label>{props.label}</label>
    //   <br></br>
    <Dropdown
      className="searchpanel-dropdown"
      placeholder={props.label}
      fluid
      selection
      // props.options is an array of type [{key,text,value}]
      value={props.value}
      options={props.options}
      onChange={(e, data) => props.onSelectDropDownChange(props.id, e, data)}
      clearable={true}
      style={{ backgroundColor: 'rgba(0,0,0,0)' }}
    />
    // </div>
  )
}

export default SelectInput;
