import api from '../config/api'

const getAllBenchmarkService = async () => {
    try {
        const { data } = await api.get('/benchmark-fund/all-benchmark-funds')

        return data
    }
    catch (err) {
        console.log("getAllBenchmarkService error: " + err);
    }
}

export default getAllBenchmarkService