import React from 'react'
import Button from "@mui/material/Button"

const SearchButton = (props) => {
    return (
        // <div style={{ marginTop: "10px" }}>
        <Button
            fullWidth={props.fullWidth || false}
            variant="contained"
            sx={{ backgroundColor: props.color || "#002752", borderRadius: "7px", marginLeft: '5px', marginRight: '5px' }}
            onClick={() => props.onSearchButtonClick()}
        >
            {props.title}
        </Button>
        // </div>
    )
}

export default SearchButton
