import { combineReducers } from 'redux'
import allFundsReducer from './allFundsReducer'
import { assetSegmentListReducer, selectedFiltersReducer, selectedFundsReducer, selectedFundsViewReducer, showViewReducer } from './commonReducers'
import allBenchmarkReducer from './getAllBenchmarkReducer'
import searchResultListReducer from "./searchResultListReducer"
import setUserDataReducer from './setUserDataReducer'

const combineReducer = combineReducers({
    allFunds: allFundsReducer,
    searchResultList: searchResultListReducer,
    selectedFunds: selectedFundsReducer,
    selectedFilters: selectedFiltersReducer,
    assetSegmentList: assetSegmentListReducer,
    showView: showViewReducer,
    allBenchmarks: allBenchmarkReducer,
    selectedFundsView: selectedFundsViewReducer,
    setUserDataReducer: setUserDataReducer
})

export default combineReducer