import React, { Component } from 'react'
import { connect } from 'react-redux';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import api from '../../config/api';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#EFF9F9",
        color: "#141414",
        fontSize: 17
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        color: "#141414",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,

    },

    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


class PeriodReturnTable extends Component {
    state = {
        data: {},
    }

    componentDidMount = async () => {
        console.log("Period Return Chart: componentDidMount");
        try {
            let data
            if (this.props.inputData.portfolio) {
                const { startDate, endDate, portfolioAmt, rebalance, weights, benchmark } = this.props.inputData
                data = await api.get(`/portfolio/charts/period-return-chart`, {
                    params: {
                        start: startDate,
                        end: endDate,
                        amount: portfolioAmt,
                        rebalance: rebalance,
                        weights: weights,
                        benchmarkId: benchmark
                    }
                })

            }
            else {
                data = await api.get(`/charts/period-return-chart/${this.props.inputData.fundID}?benchmarkId=${this.props.inputData.benchmark}&end=${this.props.inputData.endDate}&funds=${JSON.stringify(this.props.inputData.funds)}`)
            }
            this.setState({ data: data.data })
        }
        catch (e) {
            console.log(e);
        }
    }

    componentDidUpdate = async (prevProp) => {
        console.log("Period Return Chart: componentDidUpdate");
        try {
            if (prevProp.inputData !== this.props.inputData) {
                let data
                if (this.props.inputData.portfolio) {
                    const { startDate, endDate, portfolioAmt, rebalance, weights, benchmark } = this.props.inputData
                    data = await api.get(`/portfolio/charts/period-return-chart`, {
                        params: {
                            start: startDate,
                            end: endDate,
                            amount: portfolioAmt,
                            rebalance: rebalance,
                            weights: weights,
                            benchmarkId: benchmark
                        }
                    })

                }
                else {
                    data = await api.get(`/charts/period-return-chart/${this.props.inputData.fundID}?benchmarkId=${this.props.inputData.benchmark}&end=${this.props.inputData.endDate}&funds=${JSON.stringify(this.props.inputData.funds)}`)
                }
                this.setState({ data: data.data })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    render() {
        const data = this.state.data
        const rows = []
        let header
        let loading = true
        if (Object.keys(data).length > 0) {
            console.log(this.state.data);
            // rows.push(Object.values(data.fund_return))
            // rows[0].unshift(moment(this.props.inputData.endDate).format('MMM-YYYY'))
            // rows[1].unshift(moment(this.props.inputData.endDate).format('MMM-YYYY'))
            for (const key in data['fund_return']) {
                if (Object.hasOwnProperty.call(data['fund_return'], key)) {
                    const element = data['fund_return'][key];
                    rows.push(Object.values(element))
                    rows[rows.length - 1].unshift(this.props.allFunds[key] ? this.props.allFunds[key].fund_name : 'Portfolio')
                    header = Object.keys(element)
                }
            }
            rows.push(Object.values(data.index_return))
            console.log(rows);
            rows[rows.length - 1].unshift(this.props.allBenchmarks[this.props.inputData.benchmark].fund_name)
            loading = false
        }

        return (
            loading ?
                <div></div> :
                <Box sx={{ flexGrow: 1, margin: "20px", backgroundColor: 'white', borderRadius: '5px' }}>
                    <Paper sx={{ width: '100%', marginTop: "10px" }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" colSpan={header.length + 1}>
                                            {`Period Return Table as at ${moment(this.props.inputData.endDate).format('MMM DD,YYYY')}`}
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Fund Name</StyledTableCell>
                                        {header
                                            .map(v => <StyledTableCell key={v} align="right">{v}</StyledTableCell>)
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .map((row, i) => {
                                            return (
                                                <StyledTableRow key={i}>
                                                    {row.map((v, i) => (
                                                        i < 1 ? <StyledTableCell component="th" scope="row">{v}</StyledTableCell> :
                                                            <StyledTableCell key={v} align="right">{parseFloat(v * 100).toFixed(2)}%</StyledTableCell>
                                                    ))
                                                    }
                                                </StyledTableRow>
                                            )
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Box >
        );
    }
}

const mapStateToProps = (state) => ({
    allFunds: state.allFunds.funds,
    allBenchmarks: state.allBenchmarks.benchmarks
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodReturnTable)