import { Box, Skeleton } from '@mui/material';
import moment from 'moment';
import React, { Component } from 'react'
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import api from '../../config/api';

class TrackingError extends Component {
    state = {
        data: [],
        chartData: {
            labels: [],
            datasets: []
        },
        loading: true,
        isError: false
    }

    componentDidMount = async (prevProp, prevState) => {
        console.log("Tracking Error Chart: componentDidMount");
        try {
            let data
            if (this.props.inputData.portfolio) {
                const { startDate, endDate, portfolioAmt, rebalance, weights, benchmark } = this.props.inputData
                data = await api.get(`/portfolio/charts/nominal-tracking-error`, {
                    params: {
                        start: startDate,
                        end: endDate,
                        amount: portfolioAmt,
                        rebalance: rebalance,
                        weights: weights,
                        benchmarkId: benchmark
                    }
                })

            }
            else {
                data = await api.get(`/charts/nominal-tracking-error/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}&benchmarkId=${this.props.inputData.benchmark}`)
            }
            this.setState({ data: data.data, isError: false })
            this.createChartData()
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }

    }

    componentDidUpdate = async (prevProp) => {
        console.log("Tracking Error Chart: componentDidUpdate");
        try {
            if (JSON.stringify(prevProp.inputData) !== JSON.stringify(this.props.inputData)) {
                let data
                this.setState({ loading: true })
                if (this.props.inputData.portfolio) {
                    const { startDate, endDate, portfolioAmt, rebalance, weights, benchmark } = this.props.inputData
                    data = await api.get(`/portfolio/charts/nominal-tracking-error`, {
                        params: {
                            start: startDate,
                            end: endDate,
                            amount: portfolioAmt,
                            rebalance: rebalance,
                            weights: weights,
                            benchmarkId: benchmark
                        }
                    })

                }
                else {
                    data = await api.get(`/charts/nominal-tracking-error/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}&benchmarkId=${this.props.inputData.benchmark}`)
                }
                this.setState({ data: data.data, isError: false })
                this.createChartData()
            }
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }
    }

    fund_name = ""
    benchmark_name = ""

    createChartData = () => {
        const serverData = this.state.data
        // if (this.props.inputData.portfolio) {
        //     this.fund_name = 'Portfolio'
        // }
        // else 
        this.fund_name = this.props.allFunds[serverData[0].fund_id] ? this.props.allFunds[this.props.inputData.fundID].fund_name : 'Portfolio'

        this.benchmark_name = this.props.allBenchmarks[this.props.allFunds[this.props.inputData.benchmark].default_benchmark_id].fund_name

        let chartData = {
            labels: [],
            datasets: [{
                label: this.fund_name,
                fill: false,
                backgroundColor: '#1fb4d580',
                borderColor: '#1fb4d5',
                data: [],
                pointRadius: 3,
                pointHoverRadius: 5,
            }]
        }

        serverData.forEach(val => {
            chartData.labels.push(moment(val.month_year).format('MMM-YYYY').toString())
            chartData.datasets[0].data.push(val.nominal_tracking_error * 100)
        });

        this.setState({ chartData, loading: false })
    }
    render() {
        if (this.state.isError) {
            return <h3 style={{ margin: "20px" }}>No Data Available for selected Fund or Filter</h3>
        }
        return (
            this.state.loading ?
                <Box sx={{ margin: "20px", }}>
                    <Skeleton variant="rectangular" height='20px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='30px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='500px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='70px' width='100%' />
                </Box> :
                <Line data={this.state.chartData}
                    plugins={[this.props.logoDraw]}
                    options={{
                        scales: {
                            x: {
                                title: {
                                    display: true, text: 'Time',
                                    font: {
                                        size: 20,
                                        weight: 25
                                    }
                                },
                                ticks: {
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                }
                            },
                            y: {
                                title: {
                                    display: true, text: 'Tracking Error',
                                    font: {
                                        size: 20,
                                        weight: 25
                                    }
                                },
                                ticks: {
                                    callback: function (value, index, values) {
                                        return parseFloat(value).toFixed(2) + '%';
                                    },
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                }
                            }
                        },
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        const label = context.dataset.label;
                                        return label;
                                    },
                                    title: function (context) {
                                        return `${context[0].label},  ${parseFloat(context[0].formattedValue).toFixed(4)}%`
                                    },

                                },
                                bodyFont: {
                                    size: 15
                                },
                                titleFont: {
                                    size: 15
                                }
                            },
                            legend: {
                                labels: {
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                },
                                position: 'bottom'
                            },
                            title: {
                                display: true,
                                text: [`Tracking Error Chart`, `${this.fund_name} 1Y Rolling tracking error`, `against ${this.benchmark_name}`],
                                font: {
                                    size: 18,
                                    weight: 25
                                }
                            }
                        }
                    }} />
        )
    }
}

const mapStateToProps = (state) => ({
    allFunds: state.allFunds.funds,
    allBenchmarks: state.allBenchmarks.benchmarks
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingError)
