import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, ToggleButtonGroup, ToggleButton, Switch, Autocomplete, } from '@mui/material'

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

import { actions } from "../../store/actions"
import { subtractMoment } from '../../utils/utils';
import SearchButton from './SearchButton';
import SelectInput2 from './SelectInput2';
import api from '../../config/api';
import moment from 'moment';

class ChartSettings extends Component {

    state = {
        checked: true
    }

    componentDidMount = async () => {
        this.props.getAllBenchmarks()
        let currentDate = await api.get('/fund/current-date').catch((error) => console.error(error.response.data.message))
        currentDate = currentDate ? moment(currentDate.data.month_year).format("YYYY-MM-DD").toString() : subtractMoment(1, moment(), 'months')
        this.props.setFilters({
            'endDate': subtractMoment(0, currentDate, 'months'),
            'startDate': subtractMoment(1, currentDate, 'years')
        })
    }

    handleClick = () => {
        this.props.setView({ chartSettingsOpen: !this.props.showView.chartSettingsOpen })
    };

    handleChange = (e, flag) => {
        const filters = this.props.filters
        if (flag === 'period') {
            filters['startDate'] = subtractMoment(e.target.value, filters.endDate, 'months')
            filters[flag] = e.target.value
        }
        else if (flag === 'startDate') {
            filters['period'] = ""
            filters[flag] = e.target.value
        }
        else if (flag === 'excessReturn' || flag === 'showTable') {
            filters[flag] = !filters[flag]
        }
        else {
            filters[flag] = e.target.value
        }

        this.props.setFilters(filters)
    }

    onSelectDropDownChange = (id, event, data) => {
        this.props.setFilters({ "benchmark": data ? data.fund_id : "" })
    }

    render() {
        const filters = this.props.filters
        return (
            <>
                <ListItemButton onClick={this.handleClick}>
                    <ListItemIcon>
                        <StackedLineChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="CHART SETTINGS"
                        primaryTypographyProps={{
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }}
                    />
                    {this.props.showView.chartSettingsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                {/* Chart Settings Dropdown */}
                <Collapse in={this.props.showView.chartSettingsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ paddingBottom: '1rem' }}>
                        {this.props.filters.portfolio &&
                            <>
                                {this.props.showView.portfolioAssetSegmentView && < ListItem sx={{
                                    pl: 4,
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    paddingRight: '1rem',
                                }} disablePadding >
                                    <Autocomplete
                                        onChange={(e, value) => this.props.onChangePortfolio(value, 'assetClassId')}
                                        disablePortal
                                        fullWidth
                                        id="combo-box-demo"
                                        options={this.props.filters.assetClassList}
                                        getOptionLabel={options => options.name || ''}
                                        // sx={{ width: 300, marginLeft: '1rem' }}
                                        renderInput={(params) => <TextField {...params} label="Select Asset Class" />}
                                        value={{ name: filters.assetClass }}
                                    />
                                </ListItem>}
                                {this.props.showView.portfolioAssetSegmentView && <ListItem sx={{
                                    pl: 4,
                                    marginTop: '15px',
                                    marginBottom: '10px',
                                    paddingRight: '1rem',
                                }} disablePadding >
                                    <Autocomplete
                                        onChange={(e, value) => this.props.onChangePortfolio(value, 'segmentId')}
                                        fullWidth
                                        disablePortal
                                        id="combo-box-demo"
                                        options={this.props.filters.segmentList}
                                        getOptionLabel={options => options.name || ''}
                                        renderInput={(params) => <TextField {...params} label="Select Segment" />}
                                        value={{ name: filters.segment }}
                                    />
                                </ListItem>}
                                <ListItem sx={{
                                    pl: 4,
                                    marginTop: '15px',
                                    marginBottom: '10px',
                                    paddingRight: '1rem',
                                }} disablePadding >
                                    <TextField
                                        onChange={(e) => this.props.onChangePortfolio(e.target.value, 'rebalance')}
                                        autoComplete='off'
                                        fullWidth
                                        type='number'
                                        id="standard-basic"
                                        label="Reblance months"
                                        variant="outlined"
                                        // size="small"
                                        value={filters.rebalance}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                    />
                                </ListItem>
                            </>
                        }
                        {this.props.showView.startDateView &&
                            <ListItem sx={{
                                pl: 4,
                                marginTop: '10px',
                                marginBottom: '10px',
                                paddingRight: '1rem',
                            }} disablePadding>
                                <TextField
                                    id="date"
                                    value={this.props.filters.startDate}
                                    label="Start Date"
                                    type="date"
                                    // size="small"
                                    fullWidth
                                    onChange={(e) => this.handleChange(e, 'startDate')}
                                    sx={{
                                        marginTop: '10px',
                                        marginBottom: '10px',

                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                                />
                            </ListItem>
                        }

                        <ListItem sx={{
                            pl: 4,
                            marginTop: '10px',
                            marginBottom: '10px',
                            paddingRight: '1rem',
                        }} disablePadding>
                            <TextField
                                id="date"
                                label="End Date"
                                type="date"
                                // size="small"
                                onChange={(e) => this.handleChange(e, 'endDate')}
                                value={this.props.filters.endDate}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                            />

                        </ListItem>

                        {this.props.showView.periodView &&
                            <ListItem sx={{
                                pl: 4,
                                marginTop: '10px',
                                marginBottom: '10px',
                                paddingRight: '1rem',
                            }} disablePadding>
                                <ToggleButtonGroup
                                    value={this.props.filters.period}
                                    exclusive
                                    fullWidth
                                    onChange={(e) => this.handleChange(e, 'period')}
                                >
                                    <ToggleButton value="3">3M</ToggleButton>
                                    <ToggleButton value="6">6M</ToggleButton>
                                    <ToggleButton value="12">1Y</ToggleButton>
                                    <ToggleButton value="36">3Y</ToggleButton>
                                    <ToggleButton value="60">5Y</ToggleButton>
                                    <ToggleButton value="84" > 7Y</ToggleButton >

                                </ToggleButtonGroup >

                            </ListItem >
                        }

                        {this.props.showView.rollingReturnView &&
                            <ListItem sx={{
                                pl: 4,
                                marginTop: '10px',
                                marginBottom: '10px',
                                paddingRight: '1rem',
                            }} disablePadding>
                                <ListItemText primary='Rolling Return' sx={{ width: '70%' }} />
                                <ToggleButtonGroup
                                    value={this.props.filters.rollingReturn}
                                    exclusive
                                    fullWidth
                                    onChange={(e) => this.handleChange(e, 'rollingReturn')}
                                >
                                    <ToggleButton value="1Y Rolling">1Y</ToggleButton>
                                    <ToggleButton value="3Y Rolling">3Y</ToggleButton>
                                    <ToggleButton value="5Y Rolling">5Y</ToggleButton>

                                </ToggleButtonGroup >


                            </ListItem >
                        }

                        {this.props.showView.benchmarkView &&
                            <ListItem sx={{
                                pl: 4,
                                marginTop: '20px',
                                marginBottom: '10px',
                                paddingRight: '1rem',
                            }} disablePadding >
                                <SelectInput2
                                    label="Benchmark"
                                    options={Object.values(this.props.allBenchmarks)}
                                    onSelectDropDownChange={this.onSelectDropDownChange}
                                    id="benchmark"
                                    value={this.props.allBenchmarks[this.props.filters.benchmark]}
                                />
                            </ListItem>
                        }
                        {this.props.showView.excessReturnView &&
                            <ListItem sx={{
                                pl: 4,
                                marginTop: '10px',
                                marginBottom: '10px',
                                paddingRight: '1rem',
                            }} disablePadding >
                                <ListItemText primary="Excess Return" />
                                <Switch checked={this.props.filters.excessReturn} onChange={(e) => this.handleChange(e, 'excessReturn')} />
                            </ListItem>
                        }

                        {this.props.showView.showTableView &&
                            <ListItem sx={{
                                pl: 4,
                                marginTop: '10px',
                                marginBottom: '10px',
                                paddingRight: '1rem',
                            }} disablePadding >
                                <ListItemText primary="Show Table" />
                                <Switch checked={this.props.filters.showTable} onChange={(e) => this.handleChange(e, 'showTable')} />
                            </ListItem>
                        }


                        <ListItem sx={{ pl: 4, paddingRight: '1rem', }} disablePadding >
                            <SearchButton
                                onSearchButtonClick={this.props.handleShowCharts}
                                title='Show Charts'
                                fullWidth
                            />
                        </ListItem>
                    </List >
                </Collapse >
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: state.selectedFilters,
    showView: state.showView,
    allBenchmarks: state.allBenchmarks.benchmarks,
    getCurrentDate: state.getCurrentDate
})

const mapDispatchToProps = {
    setFilters: actions.selectedFiltersAction,
    setView: actions.showViewAction,
    getAllBenchmarks: actions.getAllBenchmarkAction
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartSettings)
