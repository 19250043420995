import getAllFundsService from './getAllFundsService'
import getAllBenchmarkService from './getAllBenchmarkService'
import setUserDataService from './setUserDataService';

const services = {
    getAllFundsService,
    getAllBenchmarkService,
    setUserDataService: setUserDataService
}

export default services;