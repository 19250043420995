import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Collapse, ListItemButton, ListItemIcon, ListItemText, Divider, } from '@mui/material'

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import { actions } from '../../store/actions';

class SearchCharts extends Component {

    handleClick = () => {
        this.props.setView({ searchChartsOpen: !this.props.showView.searchChartsOpen })
    };
    render() {
        const { chartNumber } = this.props.filters
        return (
            <>
                <ListItemButton onClick={this.handleClick}>
                    <ListItemIcon>
                        <StackedLineChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="SEARCH CHARTS"
                        primaryTypographyProps={{
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }}
                    />
                    {this.props.showView.searchChartsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                {/* Search Chart Dropdown */}
                <Collapse in={this.props.showView.searchChartsOpen} timeout="auto" unmountOnExit>
                    <ListItemButton
                        sx={{ pl: 4 }} onClick={() => this.props.handleChartSelection(1)} selected={chartNumber === 1}>
                        <ListItemIcon>
                            <MultilineChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Growth Chart of ₹10,000" />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} onClick={() => this.props.handleChartSelection(2)} selected={chartNumber === 2}>
                        <ListItemIcon>
                            <BarChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Period Return" />
                    </ListItemButton>

                    {/* <Divider variant="inset" /> */}
                    <ListItemButton sx={{ pl: 4 }} onClick={() => this.props.handleChartSelection(3)} selected={chartNumber === 3}>
                        <ListItemIcon>
                            <CandlestickChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Quartile Chart" />
                        <Divider />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} onClick={() => this.props.handleChartSelection(4)} selected={chartNumber === 4}>
                        <ListItemIcon>
                            <AutoGraphIcon />
                        </ListItemIcon>
                        <ListItemText primary="Risk/Return Chart" />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} onClick={() => this.props.handleChartSelection(5)} selected={chartNumber === 5}>
                        <ListItemIcon>
                            <SsidChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Rolling Return Chart" />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} onClick={() => this.props.handleChartSelection(6)} selected={chartNumber === 6}>
                        <ListItemIcon>
                            <BubbleChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Snail Trail Chart" />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} onClick={() => this.props.handleChartSelection(7)} selected={chartNumber === 7}>
                        <ListItemIcon>
                            <WaterfallChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tracking Error" />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} onClick={() => this.props.handleChartSelection(8)} selected={chartNumber === 8}>
                        <ListItemIcon>
                            <LegendToggleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Information Ratio" />
                    </ListItemButton>

                </Collapse>
                <Divider />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: state.selectedFilters,
    showView: state.showView
})

const mapDispatchToProps = {
    setView: actions.showViewAction
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchCharts)
