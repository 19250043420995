import React from 'react'

export default function index() {
    return (
        <div style={{display:"table",textAlign:"center",marginTop:"10px",height:"100vh"}}>
            <h1>Please open in laptop. If already in laptop, please maximize the browser window.</h1>
            
        </div>
    )
}
