import { Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SearchButton from './SearchButton'
import SelectInput from './SelectInput'
import SearchBox from './SearchBox'

import SearchIcon from "@mui/icons-material/Search";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { actions } from '../../store/actions'


class SearchFunds extends Component {
    state = {
        fundType: null,
        assetClass: null,
        segment: null,
        amc: null,
        fundId: null,
        fundName: null,
        listOfAssetClass: [],
        listOfFundType: [],
        listOfSegment: [],
        listOfAMC: [],
    }

    async componentDidMount() {
        try {
            await this.props.getAllFunds();

            this.extractAllDropdowns(this.props.allFunds);

        } catch (err) {
            console.log("hello", err);
            alert(err);
        }

    }
    // end of lifecycle functions

    // util functions
    extractAllDropdowns = (allFunds) => {

        const setOfAssetClass = new Set();
        const setOfFundType = new Set();
        const setOfSegment = new Set();
        const setOfAMC = new Set();

        for (let fundId in allFunds) {

            const assetClass = allFunds[fundId].asset_class_name;
            const fundType = allFunds[fundId].fund_type_name;
            const segment = allFunds[fundId].segment_name;
            const amc = allFunds[fundId].asset_management_company_name;

            setOfAssetClass.add(assetClass);
            setOfFundType.add(fundType);
            setOfSegment.add(segment);
            setOfAMC.add(amc);

            //cascading things
        }
        this.refineAndSetDropDowns(setOfAssetClass, "listOfAssetClass");
        this.refineAndSetDropDowns(setOfFundType, "listOfFundType");
        this.refineAndSetDropDowns(setOfSegment, "listOfSegment");
        this.refineAndSetDropDowns(setOfAMC, "listOfAMC");
    }


    refineAndSetDropDowns = (setOfDropDownValues, nameInState) => {
        const correspondingList = [];
        const sortedSetOfDropDownValues = [...(setOfDropDownValues)].sort();

        sortedSetOfDropDownValues.forEach((element, idx) => {
            correspondingList.push({
                key: idx,
                value: element,
                text: element
            })
        });

        this.setState({ [nameInState]: correspondingList });
    }

    //end of util functions

    //start of searchbox 
    onAutoCompleteChange = async (event, value, reason, details) => {


        if (value) {
            await this.setState({
                fundId: value.fund_id,
                assetClass: value.asset_class_name,
                fundType: value.fund_type_name,
                amc: value.asset_management_company_name,
                segment: value.segment_name,
                fundName: value.fund_name
            });

            let searchResults = [];
            searchResults.push(this.props.allFunds[this.state.fundId]);
            this.props.searchFunds(searchResults);
        }

    }
    onFundNameInputChange = async (event, value, reason) => {

        if (reason === "clear") {
            this.setState({
                fundId: null,
                fundName: null
            });
        }
        if (reason === "input") {
            await this.setState({
                fundId: null,
                fundName: value
            });

        }
    }

    //end of searchbox

    // select dropdowns

    // keys are fundType,assetClass,segment

    setCascadingDropDowns = () => {

        const listOfAssetClass = new Set();
        const listOfFundType = new Set();
        const listOfSegment = new Set();
        const listOfAMC = new Set();

        for (const key in this.props.allFunds) {
            if (Object.hasOwnProperty.call(this.props.allFunds, key)) {
                const ele = this.props.allFunds[key];

                const assetClass = this.state.assetClass ? this.state.assetClass : ele.asset_class_name;
                const fundType = this.state.fundType ? this.state.fundType : ele.fund_type_name;
                const segment = this.state.segment ? this.state.segment : ele.segment_name;
                const amc = this.state.amc ? this.state.amc : ele.asset_management_company_name;

                if (assetClass === ele.asset_class_name
                    && fundType === ele.fund_type_name
                    && amc === ele.asset_management_company_name
                    && segment === ele.segment_name
                ) {
                    listOfAssetClass.add(assetClass);
                    listOfAMC.add(amc);
                    listOfFundType.add(fundType);
                    listOfSegment.add(segment)
                }
            }
        }

        // this.props.allFunds.forEach(ele => {

        //     const assetClass = this.state.assetClass ? this.state.assetClass : ele.asset_class_name;
        //     const fundType = this.state.fundType ? this.state.fundType : ele.fund_type_name;
        //     const segment = this.state.segment ? this.state.segment : ele.segment_name;
        //     const amc = this.state.amc ? this.state.amc : ele.asset_management_company_name;

        //     if (assetClass === ele.asset_class_name
        //         && fundType === ele.fund_type_name
        //         && amc === ele.asset_management_company_name
        //         && segment === ele.segment_name
        //     ) {
        //         listOfAssetClass.add(assetClass);
        //         listOfAMC.add(amc);
        //         listOfFundType.add(fundType);
        //         listOfSegment.add(segment)
        //     }

        // });
        this.refineAndSetDropDowns(listOfAssetClass, "listOfAssetClass");
        this.refineAndSetDropDowns(listOfFundType, "listOfFundType");
        this.refineAndSetDropDowns(listOfSegment, "listOfSegment");
        this.refineAndSetDropDowns(listOfAMC, "listOfAMC");
    }
    onSelectDropDownChange = async (id, event, data) => {
        if (data.value === "") data.value = null
        await this.setState({ [id]: data.value, fundId: null, fundName: null });
        this.setCascadingDropDowns();
    }

    //end of select dropdowns

    //searchbutton
    onSearchButtonClick = () => {


        let searchResults = [];

        if (this.state.fundId) {
            searchResults.push(this.props.allFunds[this.state.fundId]);
            this.props.searchFunds(searchResults);
            console.log(this.props.searchedItems);

            return;
        }

        const searchParameters = {
            fundName: this.state.fundName,
            fundType: this.state.fundType,
            assetClass: this.state.assetClass,
            segment: this.state.segment,
            amc: this.state.amc
        }


        searchResults = this.customSearch(searchParameters);
        this.props.searchFunds(searchResults);
    }

    //O(n) implementation
    //optimize it to log(n) upon refactoring
    customSearch = (searchParameters) => {


        const allFunds = this.props.allFunds, searchResults = [];

        for (let key in allFunds) {
            let match = true;

            const fund = allFunds[key];

            if (searchParameters.fundType && searchParameters.fundType !== "all" && searchParameters.fundType !== fund.fund_type_name) match = false;
            if (searchParameters.assetClass && searchParameters.assetClass !== "all" && searchParameters.assetClass !== fund.asset_class_name) match = false;
            if (searchParameters.segment && searchParameters.segment !== "all" && searchParameters.segment !== fund.segment_name) match = false;
            if (searchParameters.amc && searchParameters.amc !== "all" && searchParameters.amc !== fund.asset_management_company_name) match = false;
            if (!match) continue;

            if (!searchParameters.fundName) searchResults.push(fund);
            else if (fund.fund_name.indexOf(searchParameters.fundName) === 0) searchResults.push(fund);

        }
        return searchResults;


    }

    handleClick = () => {
        this.props.setView({ searchFundsOpen: !this.props.showView.searchFundsOpen })
    };
    render() {
        return (
            <>
                <ListItemButton onClick={this.handleClick} >
                    <ListItemIcon>
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="SEARCH FUNDS"
                        primaryTypographyProps={{
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} />
                    {this.props.showView.searchFundsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={this.props.showView.searchFundsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
                        <ListItem sx={{
                            pl: 4,
                            marginBottom: '10px'
                        }} disablePadding>
                            <SearchBox
                                allFunds={this.props.allFunds}
                                onAutoCompleteChange={this.onAutoCompleteChange}
                                onFundNameInputChange={this.onFundNameInputChange}
                                fundName={this.state.fundName}
                            />
                        </ListItem>

                        <ListItem sx={{
                            pl: 4,
                            marginBottom: '10px'
                        }} disablePadding>
                            <SelectInput
                                label="Fund Type"
                                options={this.state.listOfFundType}
                                onSelectDropDownChange={this.onSelectDropDownChange}
                                id="fundType"
                                value={this.state.fundType}
                            />
                        </ListItem>

                        <ListItem sx={{
                            pl: 4,
                            marginBottom: '10px'
                        }} disablePadding >
                            <SelectInput
                                label="AMC"
                                options={this.state.listOfAMC}
                                onSelectDropDownChange={this.onSelectDropDownChange}
                                id="amc"
                                value={this.state.amc}
                            />
                        </ListItem>


                        <ListItem sx={{
                            pl: 4,
                            marginBottom: '10px'
                        }} disablePadding>
                            <SelectInput
                                label="Asset Class"
                                options={this.state.listOfAssetClass}
                                onSelectDropDownChange={this.onSelectDropDownChange}
                                id="assetClass"
                                value={this.state.assetClass}
                            />
                        </ListItem>

                        <ListItem sx={{
                            pl: 4,
                            marginBottom: '10px'
                        }} disablePadding>
                            <SelectInput
                                label="Segment"
                                options={this.state.listOfSegment}
                                onSelectDropDownChange={this.onSelectDropDownChange}
                                id="segment"
                                value={this.state.segment}
                            />
                        </ListItem>

                        <ListItem sx={{ pl: 4 }} disablePadding >
                            <SearchButton
                                onSearchButtonClick={this.onSearchButtonClick}
                                title='Search'
                                fullWidth
                            />
                        </ListItem>

                    </List>
                </Collapse>
                <Divider />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allFunds: state.allFunds.funds,
        isLoading: state.allFunds.isLoading,
        searchedItems: state.searchResultList,
        showView: state.showView
    }
}

const mapDispatchToProps = {
    searchFunds: actions.searchFundsAction,
    getAllFunds: actions.getAllFundsAction,
    setView: actions.showViewAction
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFunds)
