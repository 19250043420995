const moment = require('moment')

module.exports.subtractMoment = (value, date, what) => {
    let x = moment(new Date(date)).endOf('months')
    const now = moment().endOf('months')
    if (what === 'years') {
        value = value * 12
    }

    if (now.isSame(x)) {
        x = x.subtract(value + 1, 'months')
    }
    else {
        x = x.subtract(value, 'months')
    }
    x.endOf('months')
    return x.format('YYYY-MM-DD').toString()
}

