import searchFundsAction from "./searchFundsAction"
import getAllFundsAction from "./getAllFundsAction"
import getAllBenchmarkAction from "./getAllBenchmarkAction"
import setUserDataAction from './setUserDataAction'
import { selectedFundsAction, selectedFiltersAction, showViewAction, assetSegmentListAction, selectedFundsViewAction } from './commonAction'

export const actions = {

    //guidelines
    // 1. define the actions in another folder
    // 2. write key value pair
    // 3. where key is the name of the property which hold the data and value is the reference to the action   

    //harshit actions
    searchFundsAction,
    getAllFundsAction,
    selectedFundsAction,
    selectedFiltersAction,
    showViewAction,
    getAllBenchmarkAction,
    assetSegmentListAction,
    selectedFundsViewAction,
    setUserDataAction
}
