import { BoxPlotController } from '@sgratzl/chartjs-chart-boxplot'
import React, { Component } from 'react'
import { Chart as ChartJS } from 'chart.js'
import api from '../../config/api';
import { connect } from 'react-redux';
import moment from 'moment';
import { subtractMoment } from '../../utils/utils';
import { Box, Skeleton } from '@mui/material';

class QuartileChart extends Component {

    constructor(props) {
        super(props)
        this.chartRef = React.createRef();

        this.state = {
            data: [],
            chartData: {
                labels: [],
                datasets: []
            },
            loading: true,
            isError: false
        }
    }

    componentDidMount = async () => {
        console.log("Quartile Chart: componentDidMount");

        ChartJS.register(BoxPlotController)
        this.ctx = this.chartRef.current ? this.chartRef.current.getContext('2d') : null;

        try {
            let data
            if (this.props.inputData.portfolio) {
                const { startDate, endDate, portfolioAmt, rebalance, assetClassId, segmentId, weights, benchmark } = this.props.inputData
                let params
                if (this.props.inputData.excessReturn) {
                    params = {
                        start: startDate,
                        end: endDate,
                        amount: portfolioAmt,
                        rebalance: rebalance,
                        assetSegmentId: assetClassId + segmentId,
                        weights: weights,
                        benchmarkId: benchmark
                    }
                }
                else {
                    params = {
                        start: startDate,
                        end: endDate,
                        amount: portfolioAmt,
                        rebalance: rebalance,
                        assetSegmentId: assetClassId + segmentId,
                        weights: weights,
                    }
                }
                data = await api.get(`/portfolio/charts/box-chart`, {
                    params: params
                })

            }
            else {
                if (!this.props.inputData.excessReturn) {
                    data = await api.get(`/charts/box-chart/${this.props.inputData.fundID}?end=${this.props.inputData.endDate}`)
                }
                else {
                    data = await api.get(`/charts/box-chart/${this.props.inputData.fundID}?end=${this.props.inputData.endDate}&benchmarkId=${this.props.inputData.benchmark}`)
                }
            }

            this.createChartData(data.data)
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }


    }

    componentDidUpdate = async (prevProp, prevState) => {
        console.log("Quartile Chart: componentDidUpdate");
        if (this.chartRef.current) {
            this.ctx = this.chartRef.current.getContext('2d')
        }
        try {
            if (JSON.stringify(prevProp.inputData) !== JSON.stringify(this.props.inputData)) {
                this.setState({ loading: true })
                let data
                if (this.props.inputData.portfolio) {
                    const { startDate, endDate, portfolioAmt, rebalance, assetClassId, segmentId, weights, benchmark } = this.props.inputData
                    let params
                    if (this.props.inputData.excessReturn) {
                        params = {
                            start: startDate,
                            end: endDate,
                            amount: portfolioAmt,
                            rebalance: rebalance,
                            assetSegmentId: assetClassId + segmentId,
                            weights: weights,
                            benchmarkId: benchmark
                        }
                    }
                    else {
                        params = {
                            start: startDate,
                            end: endDate,
                            amount: portfolioAmt,
                            rebalance: rebalance,
                            assetSegmentId: assetClassId + segmentId,
                            weights: weights,
                        }
                    }
                    data = await api.get(`/portfolio/charts/box-chart`, {
                        params: params
                    })

                }
                else {
                    if (!this.props.inputData.excessReturn) {
                        data = await api.get(`/charts/box-chart/${this.props.inputData.fundID}?end=${this.props.inputData.endDate}`)
                    }
                    else {
                        data = await api.get(`/charts/box-chart/${this.props.inputData.fundID}?end=${this.props.inputData.endDate}&benchmarkId=${this.props.inputData.benchmark}`)
                    }
                }

                this.createChartData(data.data)
            }
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }
    }
    fund_name = ""
    benchmark_name = ""

    createChartData = (data) => {
        this.setState({ isError: false })
        const serverData = data
        const myFund = this.props.inputData.portfolio ? Object.keys(this.props.inputData.weights).join(',') : this.props.inputData.fundID
        let assetSegmentId

        if (this.chart) {
            this.chart.destroy()
        }
        if (this.props.inputData.portfolio) {
            this.fund_name = `${this.props.inputData.assetClass} - ${this.props.inputData.segment}`
            assetSegmentId = `${this.props.inputData.assetClassId}${this.props.inputData.segmentId}`
        }
        else {
            this.fund_name = `${this.props.allFunds[myFund].asset_class_name} - ${this.props.allFunds[myFund].segment_name}`
            assetSegmentId = `${this.props.allFunds[myFund].asset_class_id}${this.props.allFunds[myFund].segment_id}`
        }

        this.benchmark_name = this.props.inputData.excessReturn ? this.props.allBenchmarks[this.props.inputData.benchmark].fund_name : ''

        let chartData = {
            labels: [],
            datasets: [
                {
                    type: 'line',
                    label: `${this.props.allFunds[myFund] ? this.props.allFunds[myFund].fund_name : 'Portfolio'}`,
                    fill: false,
                    borderColor: '#002850',
                    backgroundColor: '#00285080',
                    showLine: false,
                    data: [],
                    pointRadius: 4,
                    pointHoverRadius: 6,
                    tension: 0.2,
                    borderWidth: 3,
                },
                {
                    type: 'boxplot',
                    label: `Other Funds in ${this.fund_name}`,
                    borderColor: '#269f64',
                    backgroundColor: '#269f6480',
                    // borderColor: '#1fb4d5',
                    // backgroundColor: '#1fb4d580',
                    borderWidth: 3,
                    padding: 10,
                    outlier: false,
                    outlierRadius: 0,
                    outlierColor: '#269f64',
                    itemRadius: 0,
                    data: [],
                    coef: 0,
                    meanRadius: 0,
                },
            ]
        }

        for (const key in serverData) {
            if (Object.hasOwnProperty.call(serverData, key)) {
                const v = serverData[key];
                let list = []
                for (const fid in v) {
                    if (Object.hasOwnProperty.call(v, fid)) {
                        const value = v[fid];
                        if (this.props.allFunds[fid] && `${this.props.allFunds[fid].asset_class_id}${this.props.allFunds[fid].segment_id}` === assetSegmentId) {
                            list.push(value * 100)
                        }
                    }
                }
                // const list = Object.values(v).map(val => {
                //     if (this.props.allFunds[v] && `${this.props.allFunds[v].asset_class_id}${this.props.allFunds[v].segment_id}` === assetSegmentId)
                //         return val * 100
                // })
                chartData.datasets[1].data.push(list)
                chartData.datasets[0].data.push(v[myFund.toString()] * 100)
            }
        }

        chartData.labels = Object.keys(serverData)

        this.setState({ loading: false })
        this.showChart(chartData)
    }

    showChart(boxplotData) {
        this.chart = new ChartJS(this.ctx, {
            data: boxplotData,
            plugins: [this.props.logoDraw],
            options: {
                scales: {
                    x: {
                        title: {
                            display: true, text: 'Time',
                            font: {
                                size: 20,
                                weight: 25
                            },
                        },
                        ticks: {
                            font: {
                                size: 15,
                                weight: 25
                            }
                        }
                    },
                    y: {
                        title: {
                            display: true, text: 'Return % pa',
                            font: {
                                size: 20,
                                weight: 25
                            }
                        },
                        ticks: {
                            callback: function (value, index, values) {
                                return parseFloat(value).toFixed(2) + '%';
                            },
                            font: {
                                size: 15,
                                weight: 25
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = ''
                                if (context.dataset.type === 'boxplot') {
                                    label = `Q1: ${context.formattedValue.q1}%, Median: ${context.formattedValue.median}%, Q3: ${context.formattedValue.q3}%`
                                }
                                else {
                                    label = `Return: ${context.formattedValue}%`
                                }
                                return label;
                            },
                            title: function (context) {
                                return `${context[0].dataset.label}`
                            },

                        },
                        bodyFont: {
                            size: 15
                        },
                        titleFont: {
                            size: 15
                        }
                    },
                    legend: {
                        labels: {
                            font: {
                                size: 15,
                                weight: 25
                            },

                        },
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: [`Quartile Chart`, `${this.fund_name} ${this.props.inputData.excessReturn ? 'Excess returns' : 'Returns'} as at ${moment(subtractMoment(0, this.props.inputData.endDate, 'months')).format('MMM DD, YYYY')}`, `${this.props.inputData.excessReturn ? 'against' : ''} ${this.benchmark_name}`],
                        font: {
                            size: 18,
                            weight: 25
                        }
                    }
                }
            }
        });

    }

    render() {
        if (this.state.isError) {
            return <h3 style={{ margin: "20px" }}>No Data Available for selected Fund or Filter</h3>
        }
        return (
            this.state.loading ?
                <Box sx={{ margin: "20px", }}>
                    <Skeleton variant="rectangular" height='20px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='30px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='500px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='70px' width='100%' />
                </Box> :
                <canvas ref={this.chartRef} />
        )
    }
}

const mapStateToProps = (state) => ({
    allFunds: state.allFunds.funds,
    allBenchmarks: state.allBenchmarks.benchmarks
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(QuartileChart)