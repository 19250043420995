import React from "react";
import { connect } from "react-redux";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Avatar } from "@mui/material";


class Header extends React.Component {
    componentDidMount = () => {
    };

    onHomeClick = () => {
        window.location.href = '/';
    };
    onPortfolioClick = () => {
        window.location.href = '/ratedfunds'
    };
    render() {
        return (
            <Box sx={{
                flexGrow: 1,
                backgroundColor: 'white'
            }}>
                <AppBar position="static" color='inherit' sx={{ backgroundColor: 'white' }}>
                    <Toolbar>
                        <a href="/">
                            <img src="https://www.moneymanagementindia.net/wp-content/uploads/mmi-logo-png-resized.png" alt=""
                                style={{ height: '40px' }} />
                        </a>
                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ cursor: 'pointer', display: { xs: 'none', md: 'flex' } }}>
                            <a href="https://www.moneymanagementindia.net/login/"><Avatar /></a>
                        </Box>

                    </Toolbar>

                </AppBar>
            </Box>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.userData,
    };
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
