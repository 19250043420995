import { Box, Skeleton } from '@mui/material';
import moment from 'moment';
import React, { Component } from 'react'
import { Scatter } from 'react-chartjs-2';
import { connect } from 'react-redux';
import api from '../../config/api';
import { subtractMoment } from '../../utils/utils';

class RiskReturnChart extends Component {
    state = {
        data: [],
        chartData: {
            labels: [],
            datasets: []
        },
        loading: true,
        isError: false
    }

    componentDidMount = async () => {
        console.log("Risk Return Chart: componentDidMount");
        try {
            let data
            if (this.props.inputData.portfolio) {
                const { startDate, endDate, portfolioAmt, rebalance, assetClassId, segmentId, weights, benchmark } = this.props.inputData
                let params
                if (this.props.inputData.excessReturn) {
                    params = {
                        start: startDate,
                        end: endDate,
                        amount: portfolioAmt,
                        rebalance: rebalance,
                        assetSegmentId: assetClassId + segmentId,
                        weights: weights,
                        benchmarkId: benchmark
                    }
                }
                else {
                    params = {
                        start: startDate,
                        end: endDate,
                        amount: portfolioAmt,
                        rebalance: rebalance,
                        assetSegmentId: assetClassId + segmentId,
                        weights: weights,
                    }
                }

                data = await api.get(`/portfolio/charts/risk-return-chart`, {
                    params: params
                })

            }
            else {
                if (!this.props.inputData.excessReturn) {
                    data = await api.get(`/charts/risk-return-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}`)
                        .catch(error => {
                            this.setState({ loading: false, isError: true })
                            console.log(error.response.data.message);
                        })
                }
                else {
                    data = await api.get(`/charts/risk-return-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}&benchmarkId=${this.props.inputData.benchmark}`)

                }
            }

            this.setState({ data: data.data, isError: false })
            this.createChartData()
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }

    }

    componentDidUpdate = async (prevProp) => {
        console.log("Risk Return Chart: componentDidUpdate");
        try {
            if (JSON.stringify(prevProp.inputData) !== JSON.stringify(this.props.inputData)) {
                this.setState({ loading: true })
                let data
                if (this.props.inputData.portfolio) {
                    const { startDate, endDate, portfolioAmt, rebalance, assetClassId, segmentId, weights, benchmark } = this.props.inputData
                    let params
                    if (this.props.inputData.excessReturn) {
                        params = {
                            start: startDate,
                            end: endDate,
                            amount: portfolioAmt,
                            rebalance: rebalance,
                            assetSegmentId: assetClassId + segmentId,
                            weights: weights,
                            benchmarkId: benchmark
                        }
                    }
                    else {
                        params = {
                            start: startDate,
                            end: endDate,
                            amount: portfolioAmt,
                            rebalance: rebalance,
                            assetSegmentId: assetClassId + segmentId,
                            weights: weights,
                        }
                    }

                    data = await api.get(`/portfolio/charts/risk-return-chart`, {
                        params: params
                    })
                }
                else {
                    if (!this.props.inputData.excessReturn) {
                        data = await api.get(`/charts/risk-return-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}`)
                            .catch(error => {
                                this.setState({ loading: false, isError: true })
                                console.log(error.response.data.message);
                            })
                    }
                    else {
                        data = await api.get(`/charts/risk-return-chart/${this.props.inputData.fundID}?start=${this.props.inputData.startDate}&end=${this.props.inputData.endDate}&benchmarkId=${this.props.inputData.benchmark}`)

                    }
                }
                this.setState({ data: data.data, isError: false })
                this.createChartData()
            }
        }
        catch (e) {
            this.setState({ loading: false, isError: true })
            console.log(e);
        }
    }

    fund_name = ""
    benchmark_name = ""

    createChartData = () => {
        const serverData = this.state.data
        const myFund = this.props.inputData.portfolio ? Object.keys(this.props.inputData.weights).join(',') : this.props.inputData.fundID

        if (this.props.inputData.portfolio) {
            this.fund_name = `${this.props.inputData.assetClass} - ${this.props.inputData.segment}`
        }
        else this.fund_name = `${this.props.allFunds[myFund].asset_class_name} - ${this.props.allFunds[myFund].segment_name}`

        this.benchmark_name = this.props.inputData.excessReturn ? this.props.allBenchmarks[this.props.inputData.benchmark].fund_name : ''

        let chartData = {
            datasets: [
                {
                    label: this.props.allFunds[myFund] ? this.props.allFunds[myFund].fund_name : 'Portfolio',
                    fill: false,
                    borderColor: '#002850',
                    backgroundColor: '#00285080',
                    data: [],
                    pointRadius: 7,
                    pointHoverRadius: 10,
                    pointStyle: 'rectRot',
                    showLine: true,
                    borderWidth: 3
                },
                {
                    label: `Other Funds in ${this.fund_name}`,
                    fill: false,
                    borderColor: '#269f64',
                    backgroundColor: '#269f6480',
                    // yAxisID: 'y-axis-1',
                    data: [],
                    // tension: 0.3,
                    pointRadius: 4,
                    pointHoverRadius: 7,
                    borderWidth: 3
                }
            ]
        }

        for (const key in serverData) {
            if (Object.hasOwnProperty.call(serverData, key)) {
                const v = serverData[key];
                if (key.toString() !== myFund.toString()) {
                    chartData.datasets[1].data.push({ y: v.fundReturn * 100, x: v.fundRisk * 100, fund: key })
                }
                else {
                    chartData.datasets[0].data.push({ y: v.fundReturn * 100, x: v.fundRisk * 100, fund: key })
                }
            }
        }

        this.setState({ chartData, loading: false })
    }
    render() {
        if (this.state.isError) {
            return <h3 style={{ margin: "20px" }}>No Data Available for selected Fund or Filter</h3>
        }
        const period = parseInt(this.props.inputData.period) / 12 >= 1 ? `${parseInt(this.props.inputData.period) / 12}Y` : this.props.inputData.period + 'M'
        return (
            this.state.loading ?
                <Box sx={{ margin: "20px", }}>
                    <Skeleton variant="rectangular" height='20px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='30px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='500px' width='100%' sx={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" height='70px' width='100%' />
                </Box> :
                <Scatter
                    plugins={[this.props.logoDraw]}
                    data={this.state.chartData}
                    options={{
                        scales: {
                            x: {
                                title: {
                                    display: true, text: 'Risk % pa',
                                    font: {
                                        size: 20,
                                        weight: 25
                                    },
                                },
                                ticks: {
                                    callback: function (value, index, values) {
                                        return parseFloat(value).toFixed(2) + '%';
                                    },
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                }
                            },
                            y: {
                                title: {
                                    display: true, text: 'Return % pa',
                                    font: {
                                        size: 20,
                                        weight: 25
                                    }
                                },
                                ticks: {
                                    callback: function (value, index, values) {
                                        return parseFloat(value).toFixed(2) + '%';
                                    },
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                }
                            }
                        },
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: (context) => {
                                        let label = context.raw.fund || '';
                                        if (label) {
                                            label = `Fund : ${this.props.allFunds[label] ? this.props.allFunds[label].fund_name : 'Portfolio'}`
                                        }
                                        return label;
                                    },
                                    beforeLabel: function (context) {
                                        const label = `Risk: ${context.label}%, Return: ${parseFloat(context.formattedValue).toFixed(2)}%`
                                        return label;
                                    }

                                },
                                bodyFont: {
                                    size: 15
                                }
                            },
                            legend: {
                                labels: {
                                    font: {
                                        size: 15,
                                        weight: 25
                                    }
                                },
                                position: 'bottom'
                            },
                            title: {
                                display: true,
                                text: [`Risk Return Chart`,
                                    `${this.fund_name} ${period} ${this.props.inputData.excessReturn ? 'Excess returns' : 'Returns'} as at ${moment(subtractMoment(0, this.props.inputData.endDate, 'months')).format('MMM DD, YYYY')}`,
                                    `${this.props.inputData.excessReturn ? 'against' : ''} ${this.benchmark_name}`],
                                font: {
                                    size: 18,
                                    weight: 25
                                }
                            }
                        }
                    }} />
        )
    }
}

const mapStateToProps = (state) => ({
    allFunds: state.allFunds.funds,
    allBenchmarks: state.allBenchmarks.benchmarks
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(RiskReturnChart)