import { Autocomplete, TextField } from '@mui/material';
import React from 'react'

const SelectInput2 = (props) => {
    return (
        <Autocomplete
            onChange={(event, newValue) => props.onSelectDropDownChange(props.id, event, newValue)}
            // onInputChange={(event, newInputValue) => {
            //     setInputValue(newInputValue);
            // }}
            id={props.id}
            // size="small"
            options={props.options}
            getOptionLabel={options => options.fund_name}
            sx={{ width: 300 }}
            value={props.value}
            renderInput={(params) => <TextField {...params} label={props.label} />}
        />
    )
}

export default SelectInput2
// reduce((prev, curr) => prev.concat(curr.fund_name), [])