import api from '../config/api'

const getAllFundsService = async () => {
    try {
        const { data } = await api.get('/fund/all-funds')
        let newData = {}

        data.forEach(e => {
            newData[e.fund_id] = e
        });
        return newData
    }
    catch (err) {
        console.log("getAllFundsService error: " + err);
    }
}

export default getAllFundsService